import React from 'react'
import FAQImg from "../../assets/images/faq.png";

const FAQSection = () => {
    return (
        <>
            <section className="xl:container lg:mx-auto px-0 lg:px-20 xl:px-0 mx-6 pt-24 mt-1 sm:pt-28 sm:mt-2 font-Montserrat">
                <div className='pb-16 mb-1 sm:mb-0 sm:pb-8 lg:block hidden'>
                    <h1 className="text-main-blue text-32px font-semibold ">FAQ</h1>
                    <div className='bg-project-orange mt-2 rounded h-1 w-9  '></div>
                </div>
                <div className="flex flex-col lg:flex-row-reverse justify-start lg:justify-between">
                    <div className="lg:mx-0 mx-auto">
                        <img className="mb-8 lg:mb-0 w-full" src={FAQImg} alt="faq" />
                    </div>
                    <div className='pb-16 mb-1 sm:mb-0 sm:pb-8 block lg:hidden'>
                        <h1 className="text-center text-main-blue text-32px font-semibold ">FAQ</h1>
                        <div className='bg-project-orange mt-2 rounded h-1 w-9  mx-auto'></div>
                    </div>
                    <div className="lg:mx-0 mx-[9px]">
                        <div className="flex items-center gap-2 lg:justify-start sm:justify-center  justify-start mb-4">
                            <div className='bg-project-orange faq rounded h-1 w-[14px]'></div>
                            <h1 className="font-medium project-black text-24px">How Much Can I Borrow?</h1>
                        </div>
                        <p className="lg:text-start text-center font-normal text-16px text-dark-grey mb-14 pb-px capitalize max-w-[529px] mx-auto">
                            This depends on the individual lender who will look at various factors including the state you live in and the laws that apply there (loans are not available in every state). Our vast network of lenders will typically loan between $100 and $5,000.
                        </p>

                        <div className="flex items-start sm:justify-center lg:justify-start justify-start gap-2 mb-4">
                            <div className='bg-project-orange min-w-[14px] rounded h-1 w-[14px] mt-[11px]'></div>
                            <h1 className="font-medium project-black text-24px ">How Will I Receive The Loan Money?</h1>
                        </div>
                        <p className="text-center lg:text-start font-normal text-16px text-dark-grey capitalize max-w-[529px] mx-auto">
                            answer pending...
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQSection