import Assitantimg from "assets/images/Assistantimg.svg";
import parse from "html-react-parser";
const AssistantMessageAM = ({ am }) => {
    const parsedHTML = am?.map((text, index) =>
        parse(text, {
            replace: (domNode) => {
                if (domNode.attribs && domNode.name === "h3") {
                    return (
                        <h3
                            key={index}
                            className="text-16px capitalize max-w-[235px] inline-block pb-6"
                        >
                            {domNode.children[0].data}
                        </h3>
                    );
                } else if (domNode.attribs && domNode.name === "h4") {
                    return (
                        <h4
                            key={index + 1}
                            className="text-16px capitalize max-w-[235px] inline-block pb-6"
                        >
                            {domNode.children[0].data}
                        </h4>
                    );
                } else if (domNode.attribs && domNode.name === "ul") {
                    return (
                        <ul className="text-16px capitalize max-w-[235px]  pb-6 list-disc flex flex-col gap-2 ml-6">
                            {domNode.children.map((child, index) => {
                                return (
                                    child.name === "li" && (
                                        <li key={index}>
                                            {child?.children?.[0].data}
                                        </li>
                                    )
                                );
                            })}
                        </ul>
                    );
                }
            },
        })
    );

    return (
        parsedHTML && (
            <div className="assistant-chat-message">
                <div className="flex items-start ">
                    <div className="flex flex-col max-w-[267px] ml-7px order-2 items-start ">
                        <div className=" bg-low-contrast-blue rounded-10px rounded-tl-none mt-4 p-4">
                            {parsedHTML}
                        </div>
                    </div>
                    <img
                        src={Assitantimg}
                        alt="My profile"
                        className="w-8 h-8  rounded-full order-1"
                    />
                </div>
            </div>
        )
    );
};

export default AssistantMessageAM;
