import Assitantimg from "assets/images/Assistantimg.svg";
const AgentState = () => {
    return (
        <div className="typing-info">
            <div className="flex items-end ml-15px mt-2.5">
                <div className="flex flex-col ml-7px order-2 items-start max-w-[267px] mb-2">
                    <div className="bg-low-contrast-blue rounded-10px rounded-bl-none py-1 px-2">
                        <div>
                            <span className="text-14px capitalize font-light max-w-[235px] inline-block">
                                typing...
                            </span>
                        </div>
                    </div>
                </div>
                <img
                    src={Assitantimg}
                    alt="My profile"
                    className="w-17px h-17px  rounded-full order-1"
                />
            </div>
        </div>
    );
};

export default AgentState;
