import Assitantimg from "assets/images/Assistantimg.svg";
import { useEffect, useState } from "react";

const ChatHeader = () => {
    const [agentNameState,setAgentName]=useState("Mike")

    useEffect(()=>{
       const agentName= sessionStorage.getItem("agent_name");
       if(agentName)
        setAgentName(agentName)
    },[])
    return (
        <div className="flex items-center justify-between py-4 pl-4  border-b-2 border-project-orange">
            <div className="flex items-center">
                <img
                    src={Assitantimg}
                    alt=""
                    className="w-57px h-57px rounded-full"
                />
                <div className="flex flex-col ml-15px">
                    <div className="text-2xl mb-0.5 flex items-center">
                        <span className="text-main-blue font-medium text-20px">
                            {agentNameState}
                        </span>
                    </div>
                    <span className="text-project-black text-14px font-light capitalize">
                        personal financial assistant
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ChatHeader;
