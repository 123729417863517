import Attachment from "assets/images/attachment.svg";
import Send from "assets/images/send.svg";
export const ChatCTA = () => {
    return (
        <div className="lg:mr-[55px] lg:ml-[41px] mr-14px ml-14px sm:mb-0 pb-14px">
            <div className="relative flex">
                <button
                    type="button"
                    className="inline-flex items-center justify-center lg:mr-2 mr-15px focus:outline-none"
                >
                    <img src={Attachment} alt="Attachment" />
                </button>
                <input
                    type="text"
                    placeholder="Send A Message"
                    className="w-full !focus:outline-none border-main-blue rounded-10px focus:shadow-none text-gray-600 placeholder-dark-grey px-4  py-3"
                />
                <div className="absolute right-4 items-center inset-y-0 flex">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center"
                    >
                        <img src={Send} alt="Send" />
                    </button>
                </div>
            </div>
        </div>
    );
};
