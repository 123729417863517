import React from 'react'

const LendingPolicy = () => {
  return (
    <section className="xl:container mx-auto pt-10 md:pt-12 lg:pt-88px pb-20 md:px-20 xl:px-0 px-6 font-Montserrat">
      <div>
        <h1 className='font-semibold text-32px  text-main-blue capitalize'>
          lowficolending.com Policy
        </h1>
        <div className='bg-project-orange mt-2 rounded h-1 w-[95px] mb-6'>
        </div>
        <div className="text-20px-30fh font-medium text-dark-grey">
          <p className="mb-9 capitalize">lowficolending.com wants you to make good financial decisions and have protection from dishonest personal lenders and other disreputable businesses that engage in unlawful and unethical lending practices.</p>
          <p className="mb-9 capitalize">
            Trust and Truth
          </p>
          <p className="mb-9 capitalize">
            All of lowficolending.com's practices and policies conform to the applicable provisions of fair lending legislation such as the Truth in Lending Act. Before you give your agreement to enter into a loan agreement, the lender is obligated to provide you in writing (possibly electronically) details of the exact fees, rates, charges, roll-over charges and any other applicable details. Visit the Rates and Fees section of this website to learn more what you need to consider before signing for a loan.        </p>
          <p className="mb-9 capitalize">
            Fair Lending        </p>
          <p className="mb-9 capitalize">
            The Dodd-Frank Wall Street Reform Act requires all lenders to engage only in "fair lending" practices. The Consumer Financial Protection Bureau is responsible for enforcing fair lending rules and regulations.        </p>
          <p className="mb-9 capitalize">
            Current State Regulations        </p>
          <p className="mb-9 capitalize">
            lowficolending.com encourages lenders to follow all applicable federal and state regulations. This includes conforming to local law regarding interest rates, fees, maximum loan terms, roll-over limits, cooling-off periods between loans, and any other loan terms. Those lenders that are an extension of a federally recognized Indian Entities Recognized Tribe act as independent sovereign nations and may not be required to follow local law regarding fees, rates, and any other loan terms, although such lenders must comply with federal lending laws including the Truth in Lending Act.        </p>
        </div>
      </div>
    </section>
  )
}

export default LendingPolicy