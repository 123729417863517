import React from 'react'

const RatesAndFees = () => {
  return (
    <>
      <section className="xl:container mx-auto pt-10 md:pt-12 lg:pt-88px pb-20 md:px-20 xl:px-0 px-6 font-Montserrat">
        <div>
          <h1 className='font-semibold text-32px  text-main-blue'>
            Rates & Fees
          </h1>
          <div className='bg-project-orange mt-2 rounded h-1 w-[95px] mb-6'>
          </div>
          <div className="text-20px-30fh font-medium text-dark-grey">
            <p className="mb-9 capitalize">lowficolending.com never charges any fees for its services. Lowficolending.com neither operates as a lender or lending partner, nor provides any direct lending services to its customers. Lowficolending.com simply connects potential consumers with appropriate lenders and lending partners from its network of reliable brands using the information submitted by such potential consumers.</p>
            <p className="mb-9 capitalize">
              In accordance with the Truth in Lending Act, any loan fees, as well as interest rates that may arise from a particular loan, must be fully disclosed. The law demands that such fees and interest rate details must be provided to potential customers by the lender or lending partner upon the approval of a loan. Please note that once you get approved for a loan by a lender or lending partner, you will most likely be redirected to a page that requires your e-signature. This page is not part of lowficolending.com and it belongs to the lender or lending partner that issues you the loan.
            </p>
            <p className="mb-9 capitalize">
              Full Disclosure of Terms
            </p>
            <p className="mb-9 capitalize">
              Any lender or lending partner we connect you with is responsible for providing you with documents containing all details regarding fees and interest rates related to their loan offer. Please make sure to read carefully and completely all such fee and interest rate details that are given to you by the lender or lending partner. It is crucial to read and understand those fees before accepting the loan’s terms and conditions. Remember that you have no obligation to agree to the loan’s terms. Please e-sign the terms and conditions page and accept the transfer of the loan funds only if you are certain that the loan terms provided by the lender or lending partner are acceptable for you.
            </p>
            <p className="mb-9 capitalize">
              Keep in mind that all fees and interest rates related to a loan offered by a lender or lending partner are set by the lender or lending partner. Certain amounts may depend on the details you submit to them via our website. lowficolending.com is in no way connected or has any knowledge of the loan agreements and terms between consumers and lenders or lending partners. Remember that you have no obligation to accept or move on with a loan request in case the terms provided by the lender or lending partner are not acceptable for you.
            </p>
            <p className="mb-9 capitalize">
              Late Payment Policy
            </p>
            <p className="mb-9 capitalize">
              Any lender or lending partner in our network has their own policies when it comes to late payments. Please make sure to read and understand the late payment policy featured in the documents given to you by the lender or lending partner before you accept the terms and e-sign the page. There are certain applicable regulations, laws, and rules that all lenders and lending partners in our network must abide when it comes to fees arising from late payments.
            </p>
            <p className="mb-9 capitalize">
              Non-payment Policy
            </p>
            <p className="mb-9 capitalize">
              Being unable to pay back a loan may result in extra charges or fees. lowficolending.com is dedicated to working with reliable and established lenders and lending partners that collect past due amounts in a reasonable and fair way.
            </p>
            <p className="mb-9 capitalize">
              Loan Renewal Policy
            </p>
            <p className="capitalize mb-14 pb-1">
              Please note that state laws are generally responsible for governing loan renewal policies. The final price of your loan offer may go up considerably once interest rates and late fees are calculated. This applies only to consumers in states that allow repayment term extensions or rollovers.
            </p>

            <p className="font-normal text-16pxcapitalize">
              * Note, since these explanations do not have the role of legal advice, they may not be relevant and updated at any given time. In case you have a particular question regarding any regulations that may apply to you, please refer to your lender or lending partner or ask for actual legal advice. Certain states have no applicable laws when it comes to personal loans.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default RatesAndFees