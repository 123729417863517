import { useEffect, useRef, useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { checkStatus, getChatData } from "api/chat";
import AgentState from "components/ChatPage/AgentState";
import ChatHeader from "components/ChatPage/ChatHeader";
import { ChatCTA } from "components/ChatPage/ChatCTA";
import ChatTips from "components/ChatPage/ChatTips";
import UserMessage from "components/ChatPage/UserMessage";
import Assitantimg from "assets/images/Assistantimg.svg";
import AssistantMessageAM from "components/ChatPage/AssistantMessageAM";
import AssistanMessageVC from "components/ChatPage/AssistanMessageVC";
import { useNavigate } from "react-router-dom";

const Chat = () => {
    const [chatData, setChatData] = useState([]);
    const [userChoice, setUserChoice] = useState([]);
    const [count, setCount] = useState(0);
    const [redirected, setRedirected] = useState(false);
    const [chatBodyData, setChatBodyData] = useState({
        q: "vc0",
        ua: window.navigator.userAgent,
        ip: "99.96.119.21",
        site: "lowficolending.com",
    });
    const navigate = useNavigate();

    const bottomRef = useRef(null);
    const { mutate, isLoading } = useMutation(getChatData, {
        onSuccess: (data) => {
            // Check if the question already exists in chatData
            const questionExists = chatData.some(
                (item) => item.question === data.data.question
            );

            if (!questionExists) {
                setChatData([...chatData, data.data]);
            }

            if (data?.data?.question === "vcDone") {
                setChatBodyData({
                    ...chatBodyData,
                    q: "vcDone",
                    a: "",
                    id: chatData[0]?.user_id || chatBodyData?.id,
                });
            }
        },
        onError: (err) => {
            console.error(err);
            throw err;
        },
    });

    const {
        isLoading: isCheckStatusLoading,
        isError,
        data: checkStatusData,
        error,
    } = useQuery({
        queryKey: ["todos", { id: chatData[0]?.user_id || chatBodyData?.id }],
        queryFn: () =>
            checkStatus({ id: chatData[0]?.user_id || chatBodyData?.id }),
        enabled:
            count !== 6 && chatData[chatData.length - 1]?.url ? true : false,
        refetchInterval: 60000,
        onSuccess: (data) => {
            if (data.data.url !== null) {
                setRedirected(true);
                setCount(6);
                setTimeout(() => {
                    window.location.href = data.data.url;
                }, 3000);
            } else if (data.data.url === null) {
                setCount((prevCount) => prevCount + 1);
            }
        },
        onError: () => {
            navigate("/renterror");
        },
    });

    useEffect(() => {
        mutate(chatBodyData);
    }, [chatBodyData]);

    useEffect(() => {
        try {
            if (userChoice.length > 0) {
                setChatBodyData({
                    ...chatBodyData,
                    q: chatData[chatData.length - 1]?.question,
                    a:
                        userChoice[userChoice.length - 1].apiAnswer ??
                        userChoice[userChoice.length - 1],
                    id: chatData[0]?.user_id,
                });
            }
        } catch (err) {
            console.error(err);
        }
    }, [userChoice]);

    useEffect(() => {
        if (count >= 6) {
            navigate("/renterror");
        }
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [count]);

    return (
        <section className="xl:container md:mx-auto font-Montserrat pb-60px pt-5 md:pt-9 lg:pb-35px px-6 md:px-20 xl:px-0">
            <div className="flex justify-center flex-row h-[784px]">
                <div className="flex-1 justify-between flex flex-col chat-form rounded-tl-20px rounded-bl-20px rounded-br-20px rounded-tr-20px md:rounded-tr-none md:rounded-br-none">
                    <ChatHeader />
                    <div className="flex flex-col mb-4 h-screen space-y-6 lg:pl-9 pl-3 lg:pr-9px pr-[11px] pt-11 overflow-y-auto overflow-x-hidden scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch ">
                        {chatData.map((data, index) => {
                            return (
                                <div key={index}>
                                    <AssistantMessageAM am={data.am} />
                                    <AssistanMessageVC
                                        calendar={data?.calendar}
                                        vc={data.vc}
                                        userChoice={userChoice}
                                        setUserChoice={setUserChoice}
                                    />
                                    {userChoice.length > 0 &&
                                        (userChoice[index]?.userAnswer ??
                                            userChoice[index]) && (
                                            <UserMessage
                                                choice={
                                                    userChoice[index]
                                                        ?.userAnswer ??
                                                    userChoice[index]
                                                }
                                            />
                                        )}
                                </div>
                            );
                        })}
                        {redirected && (
                            <div className="assistant-chat-message">
                                <div className="flex items-start ">
                                    <div className="flex flex-col max-w-[267px] ml-7px order-2 items-start ">
                                        <div className=" bg-low-contrast-blue rounded-10px rounded-tl-none mt-4 p-4">
                                            Great news, we can help! Please wait
                                            while I redirect you now
                                        </div>
                                    </div>
                                    <img
                                        src={Assitantimg}
                                        alt="My profile"
                                        className="w-8 h-8  rounded-full order-1"
                                    />
                                </div>
                            </div>
                        )}
                        {checkStatusData?.data?.url === null &&
                            Array.from({ length: count }).map((_, index) => (
                                <div
                                    className="assistant-chat-message"
                                    key={index}
                                    ref={bottomRef}
                                >
                                    <div className="flex items-start">
                                        <div className="flex flex-col max-w-[267px] ml-7px order-2 items-start">
                                            <div className="bg-low-contrast-blue rounded-10px rounded-tl-none mt-4 p-4">
                                                please continue to hold, we are
                                                still searching our network
                                            </div>
                                        </div>
                                        <img
                                            src={Assitantimg}
                                            alt="My profile"
                                            className="w-8 h-8 rounded-full order-1"
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                    {isLoading && <AgentState />}
                </div>
                <div className="rounded-tr-20px rounded-br-20px !border-l-0 chat-form pl-4 pr-26px pt-[220px] hidden md:block">
                    <ChatTips />
                </div>
            </div>
        </section>
    );
};

export default Chat;
