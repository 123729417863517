import HeroImage from "../../assets/images/hero-img.png";
import bg from "../../assets/images/bg.png";

const HeroSection = () => {
    return (
        <>
            <section>
                <div className="bg-main-blue font-Montserrat lg:pl-14 lg:pr-0 px-6 pb-10">
                    <div className="flex flex-col lg:flex-row-reverse xl:container justify-between md:pt-[85px] pt-[21px] lg:pt-[115px] mx-auto relative items-center lg:items-start">
                        <div className="mx-[53px] md:mx-0 mb-6 flex justify-center sm:mb-96 md:mb-0  ">
                            <div className="z-50 lg:absolute right-0 ">
                                <img
                                    src={HeroImage}
                                    className=""
                                    alt="HeroImage"
                                />
                            </div>
                        </div>
                        <div className="block sm:hidden md:block lg:mt-[0] mt-[41] mb-0 md:mb-24 lg:mb-12  pt-0 z-50">
                            <h1 className="text-center font-semibold text-white text-48px mb-4">
                                Personalized
                                <br />
                                <span className="bg-gradient-to-b from-project-orange to-project-light-orange text-transparent bg-clip-text">
                                    Digital
                                </span>{" "}
                                Loans
                            </h1>
                            <p className="text-center max-w-[384px] mx-auto text-white text-20px">
                                Ditch the banker and connect with a lender on{" "}
                                <a href="">lowficolending.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative">
                    <img
                        className=" w-full absolute xl:bottom-[-100px] bottom-[-44px]  z-0"
                        src={bg}
                        alt=""
                    />
                </div>
            </section>
        </>
    );
};

export default HeroSection;
