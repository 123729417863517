import { useState, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

const LoanAmounts = [
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "1000",
    "1500",
    "2000",
    "2500 - 5000",
];

const LoanForms = (props) => {
    const [selected, setSelected] = useState(LoanAmounts[0]);
    const navigate = useNavigate();
    return (
        <form
            className={`${props.className}`}
            onSubmit={(e) => {
                e.preventDefault();
                navigate("/chat");
            }}
        >
            <div className="flex flex-col md:flex-row md:gap-4 ">
                <div className="mb-4">
                    <label
                        htmlFor="text"
                        className="block mb-2 text-20px font-medium text-black"
                    >
                        Loan Amount
                    </label>

                    <div className="select">
                        <Listbox value={selected} onChange={setSelected}>
                            <div className="relative mt-1">
                                <Listbox.Button className="input text-gray-900 rounded-5px md:w-[268px]  w-full py-3 pl-4 flex items-center justify-between relative bg-white">
                                    <span className="block truncate">
                                        $ {selected}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {LoanAmounts.map(
                                            (LoanAmount, childIdx) => (
                                                <Listbox.Option
                                                    key={childIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                            active
                                                                ? "bg-amber-100 text-amber-900"
                                                                : "text-gray-900"
                                                        }`
                                                    }
                                                    value={LoanAmount}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${
                                                                    selected
                                                                        ? "font-medium"
                                                                        : "font-normal"
                                                                }`}
                                                            >
                                                                ${LoanAmount}
                                                            </span>
                                                            {selected ? (
                                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                    <CheckIcon
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            )
                                        )}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </div>
                </div>
                <div className={`${props.className}`}>
                    <label
                        htmlFor="text"
                        className="block mb-2 text-20px font-medium text-black"
                    >
                        What Is Your ZIP Code
                    </label>
                    <input
                        type="number"
                        className=" input text-gray-900  rounded-5px  md:w-[268px] block w-full py-3 pl-4 "
                        placeholder="What Is Your ZIP Code"
                        maxLength={5}
                        minLength={5}
                        pattern="\d{1,5}"
                        required
                    />
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:gap-4">
                {props.children}
            </div>
        </form>
    );
};

export default LoanForms;
