const TermsOfUse = () => {
    return (
        <section className="xl:container mx-auto pt-10 md:pt-12 lg:pt-88px pb-20 md:px-20 xl:px-0 px-6 font-Montserrat">
            <div>
                <h1 className="font-semibold text-32px  text-main-blue">
                    Terms of Use
                </h1>
                <div className="bg-project-orange mt-2 rounded h-1 w-[95px] mb-6"></div>
                <div className="text-20px-30fh text-dark-grey">
                    <p className="mb-9">
                        The website and brand lowficolending.com (the “site”)
                        is owned and operated by the company DatapusVentures, LTD.
                        The company conducts its business using the brand name
                        lowficolending.com referred to further in the text as
                        “lowficolending.com”, “we” or “our”. Please note that
                        lowficolending.com runs and offers this website for
                        keeping readers informed regarding the terms, conditions
                        and notices included in our Terms of Use as well as our
                        Privacy Policy. Keep in mind that whenever you use our
                        site you accept and agree without limitation to the
                        following terms of use:
                    </p>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            PRODUCTS AND SERVICES
                        </p>

                        <p className="mb-9">
                            Since the brand lowficolending.com is not a lender
                            or a lending partner, the site takes no
                            responsibility regarding any loans and loan offers
                            advertised or offered to our customers, or for any
                            credit or loan decisions concerning our customers.
                            We do not offer any loan services or products to our
                            readers via lowficolending.com. All such services
                            and products are offered to customers from lenders
                            and lending partners from our network.
                        </p>
                    </div>

                    <div>
                        <p className="mb-9 font-bold text-black">
                            E-SIGN CONSENT
                        </p>
                        <p className="mb-9">
                            Please note that by clicking on the “Get Started”
                            (or any button with the same context used for you to
                            request being connected with a reliable lender) you
                            are giving your e-signature. This means that you
                            grant us your consent and approval to send your loan
                            request to a lender within our network so that they
                            can get in touch with you. In addition, you grant us
                            your consent to send to you electronically any
                            important disclosures, including but not limited to
                            any notices lenders or lending partners are required
                            to provide you with. Furthermore, by using the
                            services of lowficolending.com you affirm that you
                            can gain access to any documents electronically sent
                            to you as well as that you have to ability to open
                            and save files in PDF format.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            LOAN INQUIRY POLICY
                        </p>
                        <p className="mb-9">
                            Please note that lowficolending.com reserves the
                            right to decline any request from its customers at
                            any time and for any reason not banned by applicable
                            laws. You also agree that lowficolending.com may
                            demand from you to provide additional verification
                            or information before processing any loan request.
                            Only after you successfully submit all necessary
                            information by filling out the short form featured
                            on this site, you will be referred to a lender or
                            lending partner, which will further consider your
                            application on the basis on their lending procedures
                            and policies. Please note that being contacted by
                            the lender or lending partner does not indicate that
                            the lender approved your loan request. Whether a
                            loan request is approved or not depends solely on
                            the lender. lowficolending.com has no
                            responsibility to take, approve or reject any loan
                            applications submitted by customers. Any time you
                            apply for a loan via lowficolending.com, you agree
                            that we can seek for alternative lenders from our
                            network as well as for alternative loan services in
                            case we cannot find a lender with the loan type you
                            initially requested.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            CREDIT REFERENCES
                        </p>
                        <p className="mb-9">
                            Please note that the lenders and lending partners
                            from our network may need to verify your personal
                            details including but not limited to social security
                            number, driver’s license and any other official
                            document of identification you have provided. In
                            addition, lenders from our network may need to
                            review and check your credit score using the
                            services of national databases such as Experian,
                            Transunion, Equifax, and other credit bureaus.
                            Whenever you submit your personal details to
                            lowficolending.com, you give permission to the
                            lenders from our network to verify your information
                            as well as to check such credit score reports.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            RESTRICTIONS
                        </p>
                        <p className="mb-9">
                            In order to submit a request for a loan, you must be
                            a legal US resident and have to be at least 18 years
                            old. Furthermore, by using our site, you agree not
                            to use it for any illegal or inappropriate
                            activities.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            COMPLIANCE WITH LAWS
                        </p>
                        <p className="mb-9">
                            When you use our site and/or buy services or
                            products from the third-party vendors of this site,
                            you accept to respect all applicable statutes,
                            regulations, ordinances as well as federal and state
                            laws. In case lowficolending.com notices any
                            violations of any applicable laws,
                            lowficolending.com reserves the right to report
                            these to the authorities. Moreover, we reserve the
                            right to start an investigation in order to discover
                            the extent and nature of such suspected and/or
                            potential violations as well as to take the
                            appropriate actions against them if we notice any
                            possible or actual violations of our Privacy Policy
                            or Terms of Use. As long as there is an ongoing
                            investigation, we have the right to reject our
                            services to customers who are under investigation as
                            well as to remove information from the
                            lowficolending.com servers in order to minimize or
                            prevent any additional violations from that
                            customer. Please note that you agree to fully
                            cooperate with us in case you become a subject of an
                            investigation. Moreover, you are aware and
                            knowledgeable of the fact that violating this
                            Privacy Policy and Term of Use may lead to you
                            becoming a subject to either criminal or civil
                            sanctions.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            INTELLECTUAL PROPERTY
                        </p>
                        <p className="mb-9">
                            Please note that everything that appears on the
                            lowficolending.com site, including but not limited
                            to characters, graphics, trademarks, titles,
                            designs, trade names, images, software, and text,
                            represent intellectual property of
                            lowficolending.com, third-party licensees and
                            anyone authorized to use those properties.
                        </p>
                        <p className="mb-9">
                            Viewing and copying any images, documents, content,
                            pages, and materials featured on the site is
                            permitted only for the purpose of submitting a
                            request for a loan. Our customers do not have the
                            right to display, distribute, transmit or
                            disseminate any images, documents, content, pages,
                            and materials featured on the site without receiving
                            prior written consent from our side. Nothing
                            featured on this site should be perceived as
                            granting any licenses or rights to any copyright,
                            trademark or intellectual property rights.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            LIMITATION OF LIABILITY AND DISCLAIMER OF WARRANTIES
                        </p>
                        <p className="mb-9">
                            Please note that lowficolending.com tries within
                            all commercially reasonable efforts to provide
                            accurate information on the site, yet that accuracy
                            of information is not guaranteed. No representations
                            are offered by lowficolending.com when it comes to
                            customers using the site or any results they may or
                            may not achieve by using the site.
                        </p>
                        <p className="mb-9">
                            PLEASE NOTE THAT lowficolending.com AND ALL OTHER
                            PARTIES INVOLVED IN THE CREATION, DELIVERY AND
                            PRODUCTION OF THIS SITE BEAR NO LIABILITY FOR ANY
                            DAMAGES (INDIRECT, DIRECT, INCIDENTAL,
                            CONSEQUENTIAL, PUNITIVE OR SPECIAL) THAT RESULT FROM
                            YOU ACCESSING OR USING THIS SITE. EVERYTHING
                            FEATURED ON THE SITE IS “AS IS” AND DOES NOT CARRY
                            ANY WARRANTY OF ANY IMPLIED OR EXPRESS DESCRIPTION,
                            INCLUDING BUT WITHOUT LIMITATIONS TO, ANY IMPLIED
                            SUITABILITY FOR A WARRANTY OF MERCHANTABILITY,
                            NON-INFRINGEMENT OR PURPOSE. ALL THESE EXCLUSION AND
                            LIMITATIONS APPLY TO THE FULLEST SCOPE AS ALLOWED BY
                            APPLICABLE LAWS.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            THIRD PARTY CONTENT
                        </p>
                        <p className="mb-9">
                            Please, note that the lowficolending.com site may
                            have a third-party website link. lowficolending.com
                            has no responsibility for the content or privacy
                            policies of such third-party websites. Moreover, you
                            should not perceive those third-party website links
                            as recommendations or endorsements of the services
                            or products of those websites or any other party in
                            association with the websites.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">PRIVACY</p>
                        <p className="mb-9">
                            The Privacy Policy of lowficolending.com, featured
                            here by reference, applies to any information and
                            data submitted via this site. You can find
                            clarification of your rights and responsibilities
                            related to any personal data you provide.
                            lowficolending.com agrees not to use any customer
                            data or information in a way considered inconsistent
                            with the purposes and limitations guaranteed in this
                            Privacy Policy. lowficolending.com has the right,
                            at lowficolending.com’s sole discretion, to update
                            the Privacy Policy at any given time and without
                            giving you prior notice. Such Privacy Policy updates
                            and modifications come into effect from the moment
                            of posting. By keep using lowficolending.com and
                            this site, you agree to those updates and
                            modifications.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">SECURITY</p>
                        <p className="mb-9">
                            Your personal data is protected by
                            lowficolending.com via well-established technology.
                            Despite taking all commercially reasonable measures
                            to protect customer personal data submitted via this
                            site, lowficolending.com does not guarantee that
                            other people will not decrypt or intercept your
                            personal data. As long as all commercially
                            reasonable security measures are applied by
                            lowficolending.com, lowficolending.com takes no
                            responsibility regarding any possible decryptions or
                            interceptions of data except as demanded by
                            applicable laws.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">SITE USE</p>
                        <p className="mb-9">
                            Please note that lowficolending.com never charges
                            any fees for using our services or this site.
                            lowficolending.com has the role of connecting
                            customers with lenders or lending partners and is
                            not an agent for you, lenders or vendors/suppliers
                            and lowficolending.com does not evaluate any loan
                            requests submitted via this site.
                            lowficolending.com may get payments from lenders
                            and any possible third parties for the services it
                            provides to them.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            LAW AND VENUE
                        </p>
                        <p className="mb-9">
                            Please note that the company DatapusVentures, 
                            LLC owns and operates with
                            lowficolending.com. When you use the
                            lowficolending.com’s site and any of its services,
                            you agree to comply with the laws of the Bangkok,
                            Pathumwan jurisdiction. Both the Privacy Policy and
                            the Terms of Use of lowficolending.com are created
                            in compliance with the laws of Bangkok, Pathumwan,
                            disregarding any law provision conflicts. Please
                            note that any legal action against
                            lowficolending.com must be via binding arbitration
                            and will be in accordance with the Bangkok,
                            Pathumwan laws. Furthermore, you agree and
                            acknowledge that any disputes with
                            lowficolending.com will be resolved in Bangkok,
                            Pathumwan as the sole jurisdiction.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            BINDING ARBITRATION
                        </p>
                        <p className="mb-9">
                            Please note that in case an issue arises as a result
                            of an interpretation of any term or provision
                            featured in the site’s Terms of Use or from any
                            customer dealings with lowficolending.com or any
                            lender lowficolending.com has connected you with
                            will be resolved by binding arbitration. Any such
                            arbitration resolutions shall be in compliance of
                            all relevant regulations of JAMS based in Bangkok,
                            Pathumwan or the American Arbitration Association.
                            Please note that the arbitrator’s decision is final
                            and binding for both parties. The winning party is
                            entitled to recovery of any arbitration-related
                            costs such as expert testimony expenses, expert
                            travel expenses, travel expenses, and any other
                            expenses related to proving or disproving the
                            arbitration claim. This recovery of costs shall be
                            provided by the other party except when the law
                            prohibits it.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            MODIFICATION
                        </p>
                        <p className="mb-9">
                            Please note that lowficolending.com has the right
                            to update the site’s Terms of Use from time to time
                            without sending a prior notice to customers. By
                            continuing to use the site after such updates and
                            modifications are made, you agree and accept any
                            such updates and modifications.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            INDEMNIFICATION
                        </p>
                        <p className="mb-9">
                            By using the lowficolending.com’s site, you accept
                            that lowficolending.com and any of its affiliates,
                            officers, agents, partners, subsidiaries and
                            employees are defended, indemnified and deemed
                            harmless from any claim, loss, liability or demand
                            such as attorney’s fees, created by any third party
                            and arising from your attempted or actual use of
                            this site.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            VIOLATION OF THE TERMS OF USE
                        </p>
                        <p className="mb-9">
                            Please note that in case lowficolending.com
                            suspects or discovers any violation of the Terms of
                            Use and/or the Privacy Policy or if
                            lowficolending.com believes that any content
                            provided by you is offensive or disruptive or
                            violates any applicable laws, lowficolending.com
                            reserves the right at its own discretion and without
                            sending a prior notice to you to restrict your
                            access to the site alongside any services offered to
                            you and to remove any such content provided by you.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            CLASS ACTION WAIVER
                        </p>
                        <p className="mb-9">
                            PLEASE NOTE THAT ANY RESOLUTION, ARBITRATION OR
                            LITIGATION ANY DISPUTES SHALL BE RESOLVED
                            EXCLUSIVELY ON THE CASE-BY-CASE PRINCIPLE. YOU AGREE
                            THAT NEITHER lowficolending.com NOR YOU WILL DEMAND
                            TO HAVE A DISPUTE RESOLVED AS A CLASS ACTION, OR
                            ALTERNATIVELY AS A PRIVATE ATTORNEY GENERAL ACTION,
                            OR ANY OTHER RESOLUTION WHERE ANY OF THE CONCERNED
                            PARTIES WILL PROPOSE TO ACT OR ACTUALLY ACT IN A
                            REPRESENTATIVE CAPACITY. NO DISPUTE, ARBITRATION OR
                            PROCEEDING SHALL BE COMBINED WITH ANOTHER DISPUTE,
                            ARBITRATION OR PROCEEDING WITHOUT A PRIOR CONSENT IN
                            WRITING PROVIDED BY ALL CONCERNED PARTIES TO ALL
                            SUCH DISPUTES, ARBITRATIONS OR PROCEEDINGS.
                            MOREOVER, BY USING THIS SITE AND lowficolending.com
                            SERVICES YOU ACKNOWLEDGE AND ACCEPT NOT TO SEEK
                            HEARING AS A CLASS ACTION OR ALTERNATIVELY A PRIVATE
                            ATTORNEY GENERAL ACTION OR ANY OTHER PROCEEDING
                            WHERE ANY CONCERNED PARTY PROPOSES TO ACT OR
                            ACTUALLY ACTS IN A REPRESENTATIVE CAPACITY ANY
                            DISPUTE AGAINST ANY LENDER WITH WHOM YOU HAVE BEEN
                            CONNECTED BY USING THIS SITE. PLEASE NOTE THAT THIS
                            CLASS ACTION WAIVER SHALL BE APPLIED TO THE FULL
                            SCOPE ALLOWED BY APPLICABLE LAWS BUT MAY BE LIMITED
                            BY APPLICABLE LAWS AS WELL.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            MISCELLANEOUS
                        </p>
                        <p className="mb-9">
                            Any references to the brand lowficolending.com
                            include the company DatapusVentures, LTD. Please note
                            that any rights that have not been expressly granted
                            to our users remain solely for lowficolending.com.
                            lowficolending.com uses the Terms of Use headings
                            to provide ease of reference and these headings do
                            not have any effect on the construction or
                            interpretation thereof. Any failure by
                            lowficolending.com to either enforce or insist upon
                            enforcing any of the provisions in these Terms of
                            Use does not work as a waiver of such provision or
                            any other provision or right. In case any of the
                            provisions featured in the Terms of Use are invalid
                            or unenforceable, that does not affect those
                            provisions in any other regard or any other
                            provision included in the Terms of Use, which apply
                            in full scope and force. Please note that the Terms
                            of Use and the Privacy Policy of lowficolending.com
                            represents the full agreement and understanding
                            between the concerned parties and regarding the
                            subject matter featured in them. They also replace
                            and supersede any previous oral or written
                            agreements regarding the subject matter.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            TERMS AND CONDITIONS REGARDING SMS
                        </p>
                        <p className="mb-9">
                            Our SMS Subscription Service is available on most
                            carrier including AT&T, T-Mobile, U.S. Cellular,
                            Sprint, Cellular One, MetroPCS, Virgin Mobile, Boost
                            and Alltel Wireless. We send 3 messages per week and
                            please note that certain Messaging and Data rates
                            may apply. To receive SMS from us you need a
                            text-enabled handset. Text STOP to 55702 in order to
                            cancel your SMS subscription. Text HELP to 55702 to
                            get information directly on your phone.
                            Alternatively, you can get in touch with us at
                            <a
                                href="mailto:questions@lowficolending.com"
                                className="underline"
                            >
                                {" "}
                                questions@lowficolending.com.
                            </a>{" "}
                            Our SMS Subscription Service will be valid and
                            ongoing until you cancel the service. Carriers take
                            no responsibility for any undelivered or delayed
                            messages.
                        </p>
                        <p className="mb-9">
                            All licensees agree to all DatapusVentures, LTD policies
                            as well as to the policies of carries when it comes
                            to user-interaction, transmission, and content of
                            the messages as explained below in the text. Any
                            violations of the clauses may lead to disabling the
                            short code on supporting carriers of Licensees.
                        </p>
                        <p className="mb-9">
                            When it comes to SMS-mobile marketing, all licensees
                            agree to follow the MMA guidelines as well as the
                            appropriate code of conduct.
                        </p>
                        <p className="mb-9">
                            All licensees must make sure that all content and
                            materials will not be or include anything:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>
                                inappropriate, including but not limited to
                                profane language, drug references, references to
                                gambling or promotion of drug use;
                            </li>
                            <li>defamatory, obscene, or unlawful;</li>
                            <li>
                                violation of intellectual property rights or
                                third-party rights;
                            </li>
                            <li>
                                false, misleading, deceiving (such as
                                information related to the source or author of
                                the messages);
                            </li>
                            <li>sexually explicit images;</li>
                            <li>promoting violence of discrimination;</li>
                            <li>assisting illegal and unlawful activities;</li>
                            <li>
                                advertising or promoting competitors of the
                                carrier in case the content is delivered to
                                customers using a certain carrier.
                            </li>
                        </ol>
                        <p className="mb-9">
                            Licensees must give all consumers instructions on
                            how to “unsubscribe” from getting messages from
                            them. These instructions must be included in the
                            first message sent by the licensee to a user. The
                            message must feature all the points listed below,
                            not particularly in the same order:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>name or identity of the licensee;</li>
                            <li>a short description of the service;</li>
                            <li>
                                the number and frequency of messages sent via
                                the service;
                            </li>
                            <li>any details regarding charging fees;</li>
                            <li>instructions on how to “unsubscribe”</li>
                            <li>contact details;</li>
                            <li>
                                information about where to seek assistance or
                                URL.
                            </li>
                        </ol>
                        <p className="mb-9">
                            The sole responsibility for providing details,
                            through the Software of DatapusVentures, LTD, to
                            consumers on how to subscribe for SMS Subscription
                            Service and how to unsubscribe from receiving any
                            campaigns in case they do not wish to receive such
                            text messages anymore belongs to the licensee. The
                            licensee guarantees and ensures that it will send
                            and keep sending text messages only to customers who
                            have subscribed for such text messages and who have
                            not used the unsubscribe option.
                        </p>
                        <p className="mb-9">
                            Subject to all the conditions and terms featured in
                            this Agreement, you hereby acknowledge and agree on
                            the following:
                        </p>
                        <p className="mb-9">
                            The service provided by us can only be used for
                            lawful and legal purposes.
                        </p>
                        <p className="mb-9">
                            You will neither access nor use any third-party
                            mailing lists for mobile phone numbers, instant
                            message screen names, email addresses or in any
                            other way prepare or distribute any unsolicited
                            messages related to our services.
                        </p>
                        <p className="mb-9">
                            You accept and acknowledge to use, access or import
                            only mailing lists where all listed parties have
                            previously given their consent and approval to
                            receive text messages from you (such lists are known
                            as “Permission Based Lists”), in relation to your
                            use of our products and services. Furthermore, you
                            hereby agree not to use or obtain any other kinds of
                            lists where members have not provided prior consent
                            to receive correspondence related to our services.
                        </p>
                        <p className="mb-9">
                            You acknowledge and accept that not all DatapusVentures,
                            LTD service messages will be received by the
                            intended recipient.
                        </p>
                        <p className="mb-9">
                            Any message related to our services you send to
                            consumers must feature the DatapusVentures, LTD
                            “unsubscribe” option that enables consumers to
                            easily remove themselves from the mailing lists.
                        </p>
                        <p className="mb-9">
                            Our licensee must include a prominent link on a
                            public website that leads to the Terms and
                            Conditions of the SMS Subscription Service, where
                            recipients can find instructions on how to either
                            update or remove their data from the mailing
                            database as well as for instructions on how to
                            unsubscribe.
                        </p>
                        <p className="mb-9">
                            DatapusVentures, LTD has the right to allow particular
                            keywords and templates within campaigns in order to
                            guarantee the continued availability of the short
                            code given to licensees via supporting wireless
                            carriers. For instance, here are the keywords that
                            will be featured in all mailing campaigns:
                        </p>
                        <p className="mb-9">
                            - (in-built-unsubscribe) STOP, QUIT, CANCEL, END,
                            UNSUBSCRIBE
                        </p>
                        <p className="mb-9">– (in-built-help) HELP</p>
                        <p className="mb-9">
                            Please note that any advertising material (printed,
                            online, television or radio) that encourages
                            participation in text-based activities with
                            licensees MUST feature the following disclaimer:
                            “Standard messaging charges may apply in accordance
                            to your carrier prices. Send HELP to [enter a short
                            code number] for additional information. Send STOP
                            to [enter a short code number] to unsubscribe from
                            the service. Visit [insert URL of T&C] to find full
                            Terms & Conditions.” When applicable, the featured
                            URL must be linked to the contest rules and/or the
                            particular promotion. Please note that the licensees
                            should consult with a legal counsel.
                        </p>
                        <p className="mb-9">
                            Licensees agree that all SMS messages will include a
                            reference to the name or brand of the licensee.
                        </p>
                        <p className="mb-9">Contests:</p>
                        <p className="mb-9">
                            Any licensees official contest rules must feature
                            the complete and full DatapusVentures, LTD provided
                            Contest Rules text. Licensees should use their legal
                            counsel for advice where and when official contest
                            rules must be advertised or posted.
                        </p>
                        <p className="mb-9">
                            The licensee must send a “REBOUND” message as an
                            entry confirmation and the message must include the
                            following details: [sponsor name], HELP to Help,
                            STOP to Unsubscribe, standard messaging charges
                            apply.
                        </p>
                        <p className="mb-9">
                            Please note that DatapusVentures, LTD does not endorse or
                            approve any message that features references to
                            alcohol or gambling. Any promotion of these topics
                            to SMS subscribers must be considered and advised by
                            the licensees’ legal counsel and in accordance with
                            FCC, FTC and all applicable state laws. The legal
                            counsel of the licensee must take into consideration
                            the fact that SMS subscribers who receive such
                            promotions could be of any age. Licensees
                            acknowledge and accept that no legal advice shall be
                            provided by DatapusVentures, LTD to them.
                        </p>
                        <p className="mb-9">
                            Licensees accept and acknowledge to make all
                            reasonable efforts to guarantee that any messages,
                            especially pushes, will go to recipients who have
                            subscribed to receive such messages from licensees.
                            All the risks and responsibilities of sending
                            messages to recipients who have not subscribed for
                            the services belong to the licensees.
                        </p>
                        <p className="mb-9">
                            Licensees acknowledge that the messaging services
                            will support only Google Chrome ™ / Internet
                            Explorer 6.0 or newer / Firefox ™ / Apple Safari ™.
                            DatapusVentures, LTD does not guarantee support in case
                            licensees decide to use the platform of DatapusVentures,
                            LTD with a different web browser.
                        </p>
                        <p className="mb-9">
                            Please note that any standards and restrictions may
                            occasionally change depending on the requirements
                            from the carrier. DatapusVentures, LTD has the right to
                            update them at any moment in order to remain
                            compliant with the requirements of the carrier and
                            the best practice standards of the industry.
                        </p>
                        <p className="mb-9">
                            ANTI-SPAM Policy – DatapusVentures, LTD grants businesses
                            and organizations a rich range of tools for
                            collecting names, email addresses, IM screen names,
                            and mobile numbers. Any services provided by Pixel
                            Tech, LLC must NOT be used for sending spam i.e.
                            unsolicited messages. DatapusVentures, LTD has a strict
                            “no tolerance” policy regarding spam. The customer
                            support team of DatapusVentures, LTD will diligently
                            track large import lists alongside messages sent to
                            a considerable number of individuals. DatapusVentures,
                            LTD will instantly ban from using their services any
                            licensee that uses the company’s services for
                            spamming. In case you know or suspect any such
                            violators, please get in touch with us immediately.
                            All messages sent using the DatapusVentures, LTD LLS
                            services must include a compulsory link for
                            unsubscribing. If that unsubscribe link is either
                            deactivated or non-existent, depending on the
                            situation, DatapusVentures, LTD shall either terminate or
                            suspend the account of that customer, until the
                            unsubscribe link is reactivated.
                        </p>
                        <p className="mb-9">
                            The Do Not Text List. In a case, a consumer has
                            demanded either verbally or in writing that they do
                            not wish to receive any Service from you or on your
                            behalf, you accept and promise to remove all their
                            data and provided information from this mailing
                            list. Furthermore, you accept and acknowledge to
                            keep any contact lists and database delivered to
                            DatapusVentures, LTD updated, before the start of the
                            DatapusVentures, LTD Services on behalf of you. You agree
                            to make sure that no email address or phone number
                            of individuals who have unsubscribed from receiving
                            any correspondence or SMS will be included in any
                            contact list (phone and/or email) you provide to us.
                            There is a Global Permanent Removal List owned and
                            maintained by DatapusVentures, LTD that includes
                            addresses and domains and that does not allow
                            customers to add to the database. You accept and
                            acknowledge that DatapusVentures, LTD reserves the right
                            to, at its own discretion, prevent the adding of any
                            mobile phone number that is included in the Global
                            Permanent Removal List and has no liability for
                            blocking such addresses from any customer database.
                            Any recipient of messages can unsubscribe from
                            receiving any further correspondence from licensees
                            via the DatapusVentures, LTD platform simply by clicking
                            on the footer of DatapusVentures, LTD, the Unsub button
                            or by contacting DatapusVentures, LTD at the
                            <a
                                href="mailto:questions@lowficolending.com"
                                className="underline"
                            >
                                {" "}
                                questions@lowficolending.com
                            </a>{" "}
                            address. You accept and understand that DatapusVentures,
                            LTD reserves the right to add individuals on its
                            Global Permanent Removal List if such a situation
                            arises.
                        </p>
                        <p className="mb-9">
                            You represent and are responsible for any cell phone
                            number owners who have subscribed or given consent
                            to receive messages or broadcasts via the Pixel
                            Tech, LLC Services. You understand and promise to
                            add a clear unsubscribe or opt-out instructions in
                            every fourth message send through the DatapusVentures,
                            LTD Services. Furthermore, you agree to work in
                            compliance with the Mobile Marketing Association
                            advocated Consumer Best Practices Guidelines.
                        </p>
                        <p className="mb-9">
                            Additionally, you acknowledge and accept to
                            immediately place on the “Do-Not-Call” (“DNC”)
                            account list any person who requests to have a DNC
                            status as well as to immediately remove them from
                            any approved contact lists provided to DatapusVentures,
                            LTD Service and DatapusVentures, LTD Sites. You accept
                            and promise to follow and stay updated with any
                            applicable national, state, local and international
                            regulations and laws. Moreover, you accept and
                            acknowledge that you hold the sole responsibility
                            for any acts or omissions occurring in your account,
                            including but not limited to any content or message
                            sent via the Services of DatapusVentures, LTD. In
                            addition, you accept and promise to stay up to date
                            with the legal status of any messages, calls,
                            campaigns, and broadcasts sent via the Services of
                            DatapusVentures, LTD by regularly visiting the following
                            websites:
                        </p>
                        <ol className=" list-decimal mb-9">
                            <li>
                                The Federal Communications Commission,
                                <a
                                    href="http://www.fcc.gov"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underline"
                                >
                                    {" "}
                                    http://www.fcc.gov
                                </a>
                            </li>
                            <li>
                                The DoNotCall Registry
                                <a
                                    href="http://www.donotcall.gov"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underline"
                                >
                                    {" "}
                                    http://www.donotcall.gov
                                </a>
                            </li>
                            <li>
                                The Federal Trade Commission,
                                <a
                                    href="http://www.ftc.gov"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underline"
                                >
                                    {" "}
                                    http://www.ftc.gov
                                </a>
                            </li>
                            <li>
                                The DNC list registry rules
                                <a
                                    href="http://www.donotcall.gov"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underline"
                                >
                                    {" "}
                                    (http://www.donotcall.gov)
                                </a>
                            </li>
                        </ol>
                        <p className="mb-9">
                            Furthermore, you must make sure to follow different
                            regulations, rules and state laws when it comes to
                            restrictions applicable to the particular text
                            message or phone call types. DatapusVentures, LTD does
                            not attempt to interpret any such regulations,
                            rules, and laws. DatapusVentures, LTD simply provides
                            this information to you as a courtesy. This does not
                            replace your responsibility to stay up to date and
                            in compliance with all applicable legal requirements
                            related to your campaigns, messages, and broadcasts
                            before sending them via the DatapusVentures, LTD
                            Services. You are solely responsible for making your
                            own informed decisions when it comes to any of your
                            campaigns, messages, and broadcasts.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            CONTACT DETAILS:
                        </p>
                        <ul className="mb-9">
                            <li>Name of Company: DatapusVentures PTE. Ltd</li>
                            <li>
                                Address: 531A Upper Cross Street #04-95 
                                Singapore, 051531
                            </li>
                            <li>
                                Email Address:{" "}
                                <a
                                    href="mailto:questions@lowficolending.com"
                                    className="underline"
                                >
                                    questions@lowficolending.com
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            DETAILS FOR ISPs:
                        </p>
                        <ul className="mb-9">
                            <li>Website: www.lowficolending.com</li>

                            <li>
                                Email Address:{" "}
                                <a
                                    href="mailto:questions@lowficolending.com"
                                    className="underline"
                                >
                                    questions@lowficolending.com
                                </a>
                            </li>
                        </ul>
                        <p className="mb-9">
                            PLEASE BE AWARE THAT IN CASE YOU HAVE ANY QUESTIONS
                            REGARDING THE STATUS OF YOUR LOAN REQUEST, YOU
                            SHOULD CONTACT THE LENDER DIRECTLY.
                            lowficolending.com IS NOT A LENDER AND THEREFORE
                            CANNOT ACCESS ANY INFORMATION REGARDING THE STATUS
                            OR APPROVAL OF YOUR LOAN.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            NOTICE AND TAKEDOWN PROCEDURES
                        </p>
                        <p className="mb-9">
                            In case you believe or have a cause to suspect that
                            any content or materials featured on this site
                            infringe your copyright or any other intellectual
                            property, you can demand the removal of any such
                            content (or access) from this site by getting in
                            touch with our own copyright agent (whose details
                            are provided below) and by providing the following
                            necessary information:
                        </p>
                        <p className="mb-9">
                            Identification of any copyrighted content that you
                            believe is being infringed. Please note that our
                            copyright agent will need a description of the
                            content and, if possible, a copy of the content or a
                            location (URL, for example) of a version that has
                            been authorized.
                        </p>

                        <ol className="list-decimal mb-9">
                            <li>
                                Identification of any copyrighted content that
                                you believe is infringed alongside its location.
                            </li>
                            <li>
                                Describe the content and, if possible, provide a
                                URL or any other relevant details that can
                                enable locating the content.
                            </li>
                            <li>
                                Please provide your name, telephone number,
                                address, and email address.
                            </li>
                            <li>
                                Your good faith statement that you believe that
                                the use of the content is not allowed by the
                                owner of the copyrights or its agent or any
                                applicable laws.
                            </li>
                            <li>
                                A statement that your submission is true and
                                “under penalty of perjury” you own the
                                copyrights of the content or that you are an
                                authorized agent of the owner of the copyrights.
                            </li>
                            <li>
                                Please provide a signature or electronic
                                equivalent either from the holder of the
                                copyrights or their authorized agent. Please
                                send all the above mentioned information to the
                                email address:
                            </li>
                        </ol>
                        <p className="mb-9">Legal Department</p>
                        <p className="mb-9">
                            Email Address:{" "}
                            <a
                                href="mailto:questions@lowficolending.com"
                                className="underline"
                            >
                                questions@lowficolending.com
                            </a>
                        </p>
                        <p className="mb-9">
                            Please make sure to include the following subject
                            line for any email submissions regarding takedown
                            requests:
                        </p>
                        <p className="mb-9">DMCA Takedown Request.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermsOfUse;
