import HeroSection from "../components/HomePage/HeroSection";
import LoanSection from "../components/HomePage/LoanSection";
import RequestLoan from "../components/HomePage/RequestLoan";
import RequirmentsSection from "../components/HomePage/RequirmentsSection";
import FAQSection from "../components/HomePage/FAQSection";
import HowItWorksSection from "../components/HomePage/HowItWorksSection";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const HomePage = () => {
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        const agentName=searchParams.get('agent')
        if(agentName){
            sessionStorage.setItem("agent_name", agentName);
        }
    })
    return (
        <>
            <HeroSection />
            <LoanSection />
            <RequestLoan />
            <RequirmentsSection />
            <FAQSection />
            <HowItWorksSection />
        </>
    );
};

export default HomePage;
