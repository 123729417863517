import React from 'react'
import CardWrapper from '../shared/CardWrapper'
import Ellipse from "../../assets/images/ellipse.svg";


const RequirmentsSection = () => {
    return (
        <>
            <section className="xl:container pt-24 mt-1 md:pt-28 md:mt-1 lg:mt-2 mx-auto px-6 font-Montserrat">
                <div className="flex gap-20 mb-1.5 flex-col justify-center md:gap-9 lg:flex-row lg:gap-8">
                    <CardWrapper className="py-12 max-w-[457px] mx-auto lg:mx-0">
                        <h1 className="text-24px font-medium text-center text-main-blue">
                            Speed & Convenience
                        </h1>
                        <div className='bg-project-orange mt-2 rounded h-1 w-20 mb-8 mx-auto'>
                        </div>
                        <p className="capitalize text-16px text-dark-grey font-normal mb-8 pl-15px pr-px">
                            You can request to be connected with a lender - fast. After this you are likely to be redirected to the website of the lender once you have provided your details. This is for you to review the loan terms, and if your request is accepted, the funds will be then be deposited directly into your bank account, often by the next business day.
                        </p>
                        <div className="pl-15px pr-px">
                            <div className="flex items-center gap-2 mb-2">
                                <img src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal ">Full Online Processing</p>
                            </div>

                            <div className="flex items-start gap-2 mb-2">
                                <img className="mt-1" src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal ">Track If A Lender Is Offering The Loan You Need</p>
                            </div>

                            <div className="flex items-start gap-2">
                                <img className="mt-1" src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal">Lenders Are Always On Standby To Receive Requests</p>
                            </div>

                        </div>
                    </CardWrapper>

                    <CardWrapper className="pt-12 pb-9 max-w-[457px] mx-auto lg:mx-0">
                        <h1 className="text-24px font-medium text-center text-main-blue">
                            Requirements To Qualify For A Personal Loan
                        </h1>
                        <div className='bg-project-orange mt-2 rounded h-1 w-20 mb-8 mx-auto'>
                        </div>
                        <p className="capitalize text-16px text-dark-grey font-normal mb-8 pl-15px pr-px">
                            You must meet the following criteria before we can connect you with a lender:
                        </p>
                        <div className="pl-15px pr-px">
                            <div className="flex items-center gap-2 mb-2">
                                <img src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal ">Be 18 Years Of Age Or Older</p>
                            </div>

                            <div className="flex items-center gap-2  mb-2">
                                <img src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal">Receive A Regular Income</p>
                            </div>

                            <div className="flex items-center gap-2 mb-2">
                                <img src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal">Require A Loan From $100-$5,000</p>
                            </div>

                            <div className="flex items-center gap-2 mb-2">
                                <img src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal ">Be A Citizen Of The U.S.</p>
                            </div>

                            <div className="flex items-center gap-2 mb-2">
                                <img src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal">Have A Bank Account</p>
                            </div>

                            <div className="flex items-start gap-2">
                                <img className="mt-1" src={Ellipse} alt="Ellipse" />
                                <p className="text-16px text-grey font-normal">Some Lenders Will Approve A Loan Regardless Of Credit Rating</p>
                            </div>


                        </div>
                    </CardWrapper>

                </div>
            </section>
        </>
    )
}

export default RequirmentsSection