import Info from "assets/images/info.svg";
import { useState } from "react";

const ChatTips = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <div>
            <div className="flex flex-col ml-2.5 order-2 items-start max-w-[187px] mb-2">
                <div
                    className={` bg-low-contrast-blue rounded-10px rounded-bl-none mt-4 p-4 ${
                        showTooltip ? "visible" : "invisible"
                    } `}
                >
                    <span className="capitalize max-w-[156px] inline-block font-light text-14px">
                        we will not contact your employer
                    </span>
                </div>
            </div>
            <div className="flex items-start gap-2 pb-[155px]">
                <img
                    src={Info}
                    alt="Info"
                    className="w-6 h-6 rounded-full cursor-pointer"
                    onMouseEnter={() => {
                        setShowTooltip(true);
                    }}
                    onMouseLeave={() => {
                        setShowTooltip(false);
                    }}
                />

                <p className="max-w-[205px] mt-2 text-14px font-ligh capitalize ">
                    we will not contact your employer. Please enter your information through
                    the following questions and we will find you the best option available.
                </p>
            </div>
        </div>
    );
};

export default ChatTips;
