import React from "react";
import RequestLoanImg from "../../assets/images/request-loan.png";
import RequestLoanImgmd from "../../assets/images/request-loan-md.png";
import RequestLoanImgsm from "../../assets/images/request-loan-sm.png";

import Button from "../shared/Button";

const RequestLoan = () => {
    return (
        <>
            <section className="xl:container mx-auto px-25px md:px-20 font-Montserrat pt-16 mt-1 sm:pt-20 sm:mt-2">
                <div className="flex justify-center  flex-col gap-12 sm:flex-row md:gap-4 lg:gap-28">
                    <img
                        className="md:block hidden "
                        src={RequestLoanImg}
                        alt="RequestLoanImg"
                    />
                    <img
                        className="md:hidden hidden  sm:block"
                        src={RequestLoanImgmd}
                        alt="RequestLoanImgmd"
                    />
                    <img
                        className="mx-auto md:mx-0 sm:hidden block"
                        src={RequestLoanImgsm}
                        alt="RequestLoanImgsm"
                    />

                    <div className="mb-8 ">
                        <h1 className="font-semibold text-32px  text-main-blue max-w-[426px]">
                            Why Request a Loan Online?
                        </h1>
                        <div className="bg-project-orange mt-2 rounded h-1 w-20 mb-8"></div>
                        <p className="text-dark-grey text-16px font-normal capitalize mb-8  max-w-[426px]">
                            Making an online request for a loan is a simple and
                            fast process. There is no need for you to wait in
                            long queues to gain approval – in fact – there is no
                            need to leave the comfort of your own home! What’s
                            more, your information is protected via the use of
                            256-bit SSL encryption technology.
                        </p>
                        <Button className="w-[268px] py-2.5">
                            Get Started
                        </Button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RequestLoan;
