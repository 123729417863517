import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import HomePage from "./pages/HomePage";
import RatesAndFees from "./pages/RatesAndFees";
import LendingPolicy from "./pages/LendingPolicy";
import HowItWorks from "./pages/HowItWorks";
import Login from "./pages/Login";
import Error from "./pages/Error404";
import Chat from "./pages/Chat";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Disclaimer from "pages/Disclaimer";
import TermsOfUse from "pages/TermsOfUse";
import './App.css'

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/ratesandfees" element={<RatesAndFees />} />
                <Route path="/howitworks" element={<HowItWorks />} />
                <Route path="/login" element={<Login />} />
                <Route path="/lendingpolicy" element={<LendingPolicy />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/*" element={<Error />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
