import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {},
});

export default chatSlice.reducer;
