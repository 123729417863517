import QuestionMark from "assets/images/QuestionMark.svg";
import Userimg from "assets/images/Userimg.svg";
import { useEffect, useRef } from "react";

const UserMessage = ({ choice }) => {
    return (
        <div className="user-chat-message">
            <div className="flex items-start justify-end">
                <div className="flex mr-2 order-1 mt-4 w-full md:w-auto">
                    <div className="bg-main-blue order-2 text-white rounded-10px rounded-tr-none py-7px px-6 text-16px">
                        <span className="inline-block ">{choice}</span>
                    </div>
                    <img
                        className="mr-auto  block md:hidden"
                        src={QuestionMark}
                        alt=""
                    />
                </div>
                <img
                    src={Userimg}
                    alt="My profile"
                    className="w-8 h-8 rounded-full order-2"
                />
            </div>
        </div>
    );
};

export default UserMessage;
