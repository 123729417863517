const PrivacyPolicy = () => {
    return (
        <section className="xl:container mx-auto pt-10 md:pt-12 lg:pt-88px pb-20 md:px-20 xl:px-0 px-6 font-Montserrat">
            <div>
                <h1 className="font-semibold text-32px  text-main-blue">
                    Privacy Policy
                </h1>
                <div className="bg-project-orange mt-2 rounded h-1 w-[95px] mb-6"></div>
                <div className="text-20px-30fh text-dark-grey">
                    <p className="mb-9">Last updated: July 1, 2019</p>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            Introduction
                        </p>

                        <p className="mb-9">
                            lowficolending.com (“We” or “Company”) respect our
                            consumers’ privacy and is dedicated to protecting it
                            via being compliant with this Privacy Policy. In the
                            Privacy Policy, you can find the possible types of
                            details lowficolending.com may collect from
                            consumers and that consumers may need to provide
                            when they use the site lowficolending.com (the
                            “Site”). Furthermore, it includes the practices for
                            using, collecting, protecting and maintaining any
                            information provided by consumers. This Privacy
                            Policy refers to data lowficolending.com collects:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>Via this Site.</li>
                            <li>
                                Via text, electronic messages, email and other
                                correspondence between the Site and consumers.
                            </li>
                        </ol>
                        <p className="mb-9">
                            We encourage our consumers to carefully read and
                            fully understand this Privacy Policy as well as all
                            our practices when it comes to collecting and
                            treating their information. You have the right to
                            avoid using this Site in case you disagree with any
                            of our practices and/or policies. However, please
                            note that you agree to our Privacy Policy by using
                            and accessing this Site. Occasionally, we may update
                            this Privacy Policy (explained in Updates of Our
                            Privacy Policy). Please note that by continuing to
                            use our Site after the policy has been updated, you
                            agree to the updated version of this Privacy Policy.
                            So, make sure to occasionally check and read such
                            policy updates.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            Individuals Under the Age of 18
                        </p>
                        <p className="mb-9">
                            Please note that our Site is not suitable or
                            targeting individuals under the age of 18. We never
                            deliberately collect any personal data from
                            individuals younger than 18. Please, avoid using or
                            providing any personal data to this Site or via the
                            Site’s features in case you are under the age of 18.
                            Upon discovery, we immediately delete any personal
                            data from individuals under 18 who have not provided
                            parental consent. In case you have a reason to
                            suspect that we may have collected and stored any
                            personal data from individuals under 18, please
                            contact us at 531A Upper Cross Street 
                            #04-95 Singapore, 051531
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            How and What Data We Collect About Our Consumers
                        </p>
                        <p className="mb-9">
                            Please note that there are several main types of
                            data that we collect from our consumers and the
                            users of this Site, including the following:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>
                                data that enables your personal identification,
                                like name, home address, email, phone number,
                                bank details, social security number and/or any
                                other personal data that makes it possible for
                                you to be contacted either offline or online
                                (“personal data”);
                            </li>
                            <li>
                                data that provides information about you but
                                that cannot be used for identifying you; and/or
                            </li>
                            <li>
                                data regarding the Internet connection you have,
                                any equipment you may use to enter this Site as
                                well as usage details.
                            </li>
                        </ol>
                        <p className="mb-9">
                            These are the possible ways we may collect this
                            personal data:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>
                                Directly from our consumers when they submit it
                                to us.
                            </li>
                            <li>
                                Automatically as consumers navigate and use our
                                Site. Such data may include IP address, cookie
                                information and usage details.
                            </li>
                            <li>
                                From other third parties such as for instance,
                                our publishers.
                            </li>
                        </ol>
                        <p className="mb-9">
                            <span className="font-bold">
                                {" "}
                                Data You Submit to Us.
                            </span>{" "}
                            Any data we collect from or via our Site may
                            include:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>
                                Data you submit by completing forms featured on
                                our Site. Here comes any data you enter while
                                you submit a loan inquiry. Please note that by
                                completing forms found on our Site or by
                                providing any data to us, you accept and promise
                                that the data provided is correct, true and
                                complete. In addition, you promise not to enter
                                any misleading or false names or names that you
                                have no permission to use. We reserve the right,
                                at our sole discretion, to refuse consumers
                                access to our Site and services as well as to
                                seek for proper legal remedies in case we
                                suspect that you have provided us with
                                incorrect, incomplete or false data.
                            </li>
                            <li>
                                Any correspondence copies and records (email
                                address included), in case you ever contact us.
                            </li>
                            <li>
                                Any responses you have provided in surveys that
                                you may have been asked to fill out for our
                                research purposes.
                            </li>
                            <li>
                                Any details regarding transactions you have
                                carried out via our Site.
                            </li>
                        </ol>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            Data We Collect Via Technologies for Automatic Data
                            Collection
                        </p>
                        <p className="mb-9">
                            Please note that we may use technologies for
                            automatic data collection regarding our consumers’
                            browsing actions, patterns, and equipment as they
                            interact and use our Site. These data may include:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>
                                Information regarding your Site visits, such as
                                location data, traffic data, any logs as well as
                                other resources and communication data you use
                                and have with the Site.
                            </li>
                            <li>
                                Internet connection details and computer
                                information such as operating system, browser
                                type, and IP address.
                            </li>
                        </ol>
                        <p className="mb-9">
                            Please note that we do not collect personal data
                            automatically but only statistical information. We
                            may keep or associate the data with collected
                            personal data via other ways or received from other
                            parties. This helps us to upgrade our Site as well
                            as to offer a more personalized and improved
                            services, which result from us being able to:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>
                                Establish usage patterns and audience size of
                                our Site.
                            </li>
                            <li>
                                Keep data regarding consumer preferences,
                                leading towards our customization of the Site to
                                suit consumers’ individual interests.
                            </li>
                            <li>Improve the speed of searches.</li>
                            <li>Recognize returning customers to our Site.</li>
                        </ol>
                        <p className="mb-9">
                            We may use the following automatic data collection
                            technologies:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>
                                <span className="font-bold">
                                    {" "}
                                    Cookies (browser cookies).
                                </span>{" "}
                                Cookies are small files added to your computer’s
                                hard drive. You have the right to refuse the use
                                of cookies by selecting the appropriate settings
                                on your Internet browser. Please note that
                                selecting such settings may result in you being
                                unable to enter some parts of this Site. Our
                                system will automatically place cookies any time
                                you access our Site unless you have previously
                                selected the browser setting to refuse all or
                                our cookies.
                            </li>
                            <li>
                                <span className="font-bold">
                                    {" "}
                                    Flash Cookies.
                                </span>{" "}
                                Flash cookies are locally stored object that we
                                may use via some of our Site features in order
                                to get and keep data regarding your navigation
                                as well as preferences on, to and from this
                                Site. Please note that the browser settings that
                                manage browser cookies do not manage flash
                                cookies. For more details regarding managing
                                privacy and security Flash cookies settings,
                                please refer to the Choices About How We Use
                                Your Data section.
                            </li>
                            <li>
                                <span className="font-bold"> Web Beacons.</span>{" "}
                                Certain Site pages and emails may have web
                                beacons, which are small electronic files also
                                called pixel tags, single-pixel gifs or clear
                                gifs. These allow the Company, for instance, to
                                count the number of users that opened a
                                particular email message or visited a particular
                                page as well as offer insight to other site
                                statistics. These include server integrity,
                                content popularity, and integrity of the
                                verifying system.
                            </li>
                        </ol>
                        <p className="mb-9">
                            Please note that despite not collecting any personal
                            data automatically, we may relate this automatically
                            collected data to personal data about our consumers
                            that we collected either from our consumers or from
                            third-party sources.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            How We Use Your Data
                        </p>
                        <p className="mb-9">
                            Any personal data you either provided us with or we
                            collected may be used in the following ways or for
                            the following purposes:
                        </p>
                        <ol className="marker:text-main-blue list-disc mb-9">
                            <li>
                                To provide our Site and our Site’s content to
                                you.
                            </li>
                            <li>
                                To give you the services, information or
                                products that you may request from us.
                            </li>
                            <li>To help you find a reliable lender.</li>
                            <li>
                                To keep you notified regarding any services or
                                products on our offer.
                            </li>
                            <li>
                                For any other purpose you provided the data for.
                            </li>
                            <li>
                                To both enforce our rights and meet our
                                obligations resulting from any agreements
                                between us and you, including but not limited to
                                collection and billing.
                            </li>
                            <li>
                                For any other purpose you have given your
                                consent for.
                            </li>
                            <li>
                                For anything else explained to you when you
                                submit your data.
                            </li>
                        </ol>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            Data Security
                        </p>
                        <p className="mb-9">
                            In order to ensure that your personal data does not
                            get accidentally lost or accessed, used, altered or
                            disclosed without authorization, we implement
                            security measures. So, we store any data you submit
                            to us on secure servers and behind firewalls.
                        </p>
                        <p className="mb-9">
                            Please note that the security and safety of your
                            data depend on you too. You have the sole
                            responsibility to keep confidential any passwords
                            that we may have given to you or you may have chosen
                            to receive. We ask and urge you not to give or share
                            that password with third parties.
                        </p>
                        <p className="mb-9">
                            Unfortunately, transmitting data via the internet
                            comes with certain risks and it is not 100% secure.
                            Consequently, we are not able to guarantee that the
                            transmission of your personal data to this Site is
                            100% secure, even though we try our best to secure
                            such personal data. You transmit personal data at
                            your own risk. We do not accept any responsibility
                            for avoidance of security measures or privacy
                            settings involved on this Site.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            Updates of Our Privacy Policy
                        </p>
                        <p className="mb-9">
                            Per our policy, we post any updates of our Privacy
                            Policy on this particular page. You can find the
                            Privacy Policy last update date at the top of this
                            page. It is your responsibility to make sure that
                            the email address we have for you is deliverable and
                            active. Also, you are responsible to occasionally
                            check this Site and Privacy Policy for any possible
                            updates or changes.
                        </p>
                    </div>
                    <div>
                        <p className="mb-9 font-bold text-black">
                            Contact Information
                        </p>
                        <p className="mb-9">
                            In case you have any comments and/or questions
                            regarding our Privacy Policy and/or privacy
                            practices, please contact us at 531A Upper Cross Street 
                            #04-95 Singapore, 051531.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
