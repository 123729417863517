import React from 'react'
import Security from "../assets/images/sectigosecurity.png";
import Button from '../components/shared/Button';

const Login = () => {
  return (
    <section className="mx-auto xl:container font-Montserrat px-[22px] pt-14 mt-0.5 md:pt-44 md:mt-2 lg:pt-[71px] lg:mt-0 pb-24 mb-px md:pb-72 md:mb-0 lg:pb-16 lg:mb-0">
      <div className="login border-2 transition-all ease-in duration-300 border-project-orange py-60px px-5 rounded-10px max-w-[552px] mx-auto md:mb-8  mb-11">
        <div className="text-center">
          <h1 className="font-medium text-20px mb-2.5">Welcome Back</h1>
          <h1 className="font-medium text-24px mb-8">Login To <span className="bg-gradient-to-b from-project-orange to-project-light-orange text-transparent bg-clip-text text-28px">Your Account</span></h1>
        </div>

        <div className="max-w-[362px] mx-auto px-3">
          <div className="mb-4 mx-auto ">
            <label for="text" className="block mb-2 text-20px font-medium text-black ">Loan Amount</label>
            <input type="text" className=" input text-gray-900  rounded-5px block w-full py-3 pl-4 mx-auto" placeholder="Phone Number" required />
          </div>
          <div className="mb-8 mx-auto ">
            <label for="text" className=" block mb-2 text-20px font-medium text-black">Loan Amount</label>
            <input type="text" className=" input text-gray-900 rounded-5px block w-full py-3 pl-4 mx-auto" placeholder="MM/DD/YY" required />
          </div>
          <Button className="h-full flex-shrink-0 w-full  px-5 py-2.5 mx-auto">Submit</Button>

        </div>


      </div>
      <div className="mt-px">
        <p className="capitalize text-center max-w-[424px] mx-auto font-light text-14px mb-4">
          By completing this form and submitting your information you agree to our <a href="https://lowficolending.com/Home/Disclaimer">Disclaimer</a> and <a href="https://lowficolending.com/Home/PrivacyPolicy">Privacy Policy</a>.
        </p>
        <img className=" mx-auto" src={Security} alt="sectigosecurity" />
      </div>
    </section>
  )
}

export default Login 