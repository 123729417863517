const Button = (props) => {
    return (
        <button
            onClick={props.onClick}
            className={`font-Montserrat text-center border-gradient transition-all ease-in duration-400  bg-gradient-to-b hover:text-transparent from-project-orange to-project-light-orange 
     hover:bg-clip-text rounded-10px text-white text-16px font-normal 
      ${props.className}  `}
        >
            {props.children}
        </button>
    );
};

export default Button;
