import React from 'react'

const CardWrapper = (props) => {
  return (
    <>
      <div className={`font-Montserrat rounded-10px card-wrapper ${props.className} `}>
        {props.children}
      </div>
    </>
  )
}

export default CardWrapper