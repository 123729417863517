import Assitantimg from "assets/images/Assistantimg.svg";
import parse, { attributesToProps } from "html-react-parser";
import { useState, Fragment, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { FormatToUsDate } from "lib/FormatToUsDate";
const AssistanMessageVC = ({ vc, userChoice, setUserChoice, calendar }) => {
    const [clickedButtonValue, setClickedButtonValue] = useState({
        apiAnswer: "",
        userAnswer: "",
    });

    const [isCalendar, setIsCalendar] = useState(false);

    const bottomRef = useRef(null);

    const [value, onChange] = useState(new Date());

    const [selected, setSelected] = useState(null);

    const parsedHTML = parse(vc?.[0] ? vc[0] : "", {
        replace: (domNode) => {
            if (domNode.attribs && domNode.name === "label") {
                return (
                    <label className="text-16px capitalize max-w-[235px] inline-block pb-6">
                        {domNode.children[0].data}
                    </label>
                );
            } else if (domNode.attribs && domNode.name === "button") {
                return (
                    <button
                        value={domNode.attribs.value}
                        id={domNode.attribs.id}
                        className="py-7px px-29px  bg-white text-dark-grey rounded-10px text-15px font-normal transition-all ease-in-out duration-300 hover:bg-main-blue hover:text-white "
                    >
                        {domNode.children[0].data}
                    </button>
                );
            } else if (domNode.attribs && domNode.name === "input") {
                const props = attributesToProps(domNode.attribs);
                return (
                    <input
                        {...props}
                        className="input text-gray-900 rounded-5px block w-full py-3 pl-4 mx-auto mb-4"
                    />
                );
            } else if (domNode.attribs && domNode.name === "select") {
                const label = domNode?.children.find(
                    (child) => child?.attribs?.value === selected
                )?.children?.[0]?.data;

                const selectedDefaultValue = domNode?.children?.[1].children[0];
                if (!label) {
                    setSelected(selectedDefaultValue?.parent?.attribs?.value);
                }

                return (
                    <div className="select w-60">
                        <Listbox
                            value={
                                selected
                                    ? selected
                                    : selectedDefaultValue?.parent?.attribs
                                          ?.value
                            }
                            onChange={setSelected}
                        >
                            <div className="relative mt-1">
                                <Listbox.Button className="relative min-h[36px] flex justify-between items-center w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-3 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="block truncate">
                                        {label || selectedDefaultValue?.data}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {domNode.children.map(
                                            (child, childIdx) =>
                                                child?.attribs?.value ? (
                                                    <Listbox.Option
                                                        key={childIdx}
                                                        className={({
                                                            active,
                                                        }) =>
                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                active
                                                                    ? "bg-amber-100 text-amber-900"
                                                                    : "text-gray-900"
                                                            }`
                                                        }
                                                        value={
                                                            child?.attribs
                                                                ?.value
                                                        }
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={`block truncate ${
                                                                        selected
                                                                            ? "font-medium"
                                                                            : "font-normal"
                                                                    }`}
                                                                >
                                                                    {
                                                                        child
                                                                            ?.children?.[0]
                                                                            .data
                                                                    }
                                                                </span>
                                                                {selected ? (
                                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ) : null
                                        )}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </div>
                );
            }
        },
    });

    const buttons = parsedHTML.filter((item) => item.type === "button");

    const label = parsedHTML.find((item) => item.type === "label");

    const input = parsedHTML.find((item) => item.type === "input");

    const select = parsedHTML.find((item) =>
        item.props?.className?.includes("select")
    );

    const handleUserChoice = (e) => {
        e.preventDefault();
        let userPickedChoice;

        if (e.target[0].tagName === "BUTTON") {
            userPickedChoice = clickedButtonValue;
        } else if (isCalendar) {
            userPickedChoice = new Intl.DateTimeFormat("en-US").format(value);
        } else if (selected) {
            userPickedChoice = selected;
        } else {
            userPickedChoice = e.target[0].value;
        }

        setUserChoice([
            ...userChoice,
            isCalendar
                ? FormatToUsDate(value)
                : selected
                ? selected
                : userPickedChoice,
        ]);
        setClickedButtonValue("");
        setIsCalendar(false);
        setSelected(null);
    };

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [vc]);

    useEffect(() => {
        if (calendar) {
            setIsCalendar(true);
        }
    }, [calendar, isCalendar]);

    return (
        (parsedHTML?.length > 0 || isCalendar) && (
            <>
                {" "}
                <div ref={bottomRef} className="assistant-chat-message">
                    <div className="flex items-start ">
                        <div className="flex flex-col max-w-[267px] ml-7px order-2 items-start ">
                            <form
                                className=" bg-low-contrast-blue rounded-10px rounded-tl-none mt-4 p-4 max-w-full"
                                onSubmit={handleUserChoice}
                            >
                                <div>{label}</div>
                                <div>{input}</div>
                                {input?.props?.type === "email" && (
                                    <div className="p-4 rounded-10px bg-blue-700 text-white">
                                        Your form is submitted. To ensure you
                                        have the highest chances of approval,
                                        please do not apply on another loan
                                        sites. If you are not approved today,
                                        you can try to submit your application
                                        here tomorrow when new lenders will be
                                        available.
                                    </div>
                                )}
                                <div className="mb-4">{select}</div>
                                {isCalendar && (
                                    <div className="flex justify-center flex-col items-center gap-4 ">
                                        <Calendar
                                            onChange={onChange}
                                            value={value}
                                        />
                                        <button className="py-7px px-29px  bg-white text-dark-grey rounded-10px text-15px font-normal transition-all ease-in-out duration-300 hover:bg-main-blue hover:text-white ">
                                            Submit
                                        </button>
                                    </div>
                                )}
                                <div className="flex flex-wrap gap-4 justify-center">
                                    {buttons.map((button, index) => {
                                        const props = attributesToProps(
                                            button.props
                                        );
                                        return (
                                            <button
                                                {...props}
                                                key={index}
                                                value={props.value}
                                                onClick={(e) => {
                                                    setClickedButtonValue({
                                                        apiAnswer:
                                                            e.target.value,
                                                        userAnswer:
                                                            e.target
                                                                .textContent,
                                                    });
                                                }}
                                            >
                                                {button.props.children}
                                            </button>
                                        );
                                    })}
                                </div>
                            </form>
                        </div>
                        <img
                            src={Assitantimg}
                            alt="My profile"
                            className="w-8 h-8  rounded-full order-1"
                        />
                    </div>
                </div>
                {input?.props?.type === "email" && (
                    <div className="max-w-[267px] ml-[39px]">
                        By clicking "Request a loan", I affirm that I have read,
                        understand, and agree to the{" "}
                        <a
                            className="underline font-bold"
                            href={`${window.location.origin}/disclaimer`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            Disclaimer
                        </a>
                        ,{" "}
                        <a
                            className="underline font-bold"
                            target="_blank"
                            href={`${window.location.origin}/privacypolicy`}
                            rel="noreferrer"
                        >
                            Privay Policy
                        </a>
                        , and{" "}
                        <a
                            className="underline font-bold"
                            target="_blank"
                            href={`${window.location.origin}/termsofuse`}
                            rel="noreferrer"
                        >
                            Terms of use
                        </a>
                        , my click is my electronic signature, and I authorize
                        you to share my information with lenders and other
                        partners in order to receive a loan and accept that
                        lowficolending.com might use SMS messaging, autodialers
                        or prerecorded messages to call or text me on
                        904-324-1134. I understand consent is not required to
                        obtain a loan. I further understand that my consent to
                        be contacted is not a condition to getting the services
                        offered and that i have no obligation to accept a loan
                        once I am connected with an available lender
                    </div>
                )}
            </>
        )
    );
};

export default AssistanMessageVC;
