import React from "react";
import HowDoesItWork from "../../assets/images/how_does_it_work.png";

const HowItWorksSection = () => {
    return (
        <>
            <section className="xl:container lg:mx-auto px-0 lg:px-20 xl:px-0 mx-6 sm:mx-20 sm:px-1 pt-24 mt-1 sm:pt-28 sm:mt-2 font-Montserrat pb-24 mb-1 md:pb-10 md:mb-0.5 lg:pb-28 lg:mb-2">
                <div className="flex flex-col lg:flex-row justify-start lg:justify-between gap-0 lg:gap-[58px]">
                    <div className="lg:mx-0 mx-auto flex-shrink-0">
                        <img
                            className="mb-8 lg:mb-0 w-full"
                            src={HowDoesItWork}
                            alt="HowDoesItWork"
                        />
                    </div>
                    <div className="pb-8 block lg:hidden ">
                        <h1 className=" text-main-blue text-32px font-semibold ">
                            How Does It Work
                        </h1>
                        <div className="bg-project-orange mt-2 rounded h-1 w-9"></div>
                    </div>
                    <div className="lg:mx-0 ">
                        <div className="pb-16 mb-1 sm:mb-0 sm:pb-8 lg:block hidden">
                            <h1 className="text-main-blue text-32px font-semibold ">
                                How Does It Work
                            </h1>
                            <div className="bg-project-orange mt-2 rounded h-1 w-8  "></div>
                        </div>
                        <p className=" font-normal text-16px text-dark-grey mb-9 lg:mb-4 capitalize max-w-[585px] ">
                            Making a request for a personal loan is easy with
                            lowficolending.com. All you have to do is fill out
                            one form and your inquiry will be forwarded to
                            our network of lenders. Loan amounts granted may range
                            from $100 to $5,000 (amounts will vary based on the
                            programs offered by lenders and your state of
                            residence).
                        </p>

                        <p className=" font-normal text-16px text-dark-grey mb-9 lg:mb-4 capitalize lg:max-w-[529px] max-w-[585px] ">
                            You can receive a response from a lender the same day,
                            and often within minutes. Once you have approval for
                            your loan, you will be sent to the lender's website
                            where you can read the loan terms, including all
                            relevant details of the applicable rates and fees.
                        </p>

                        <p className=" font-normal text-16px text-dark-grey capitalize max-w-[585px] ">
                            If you accept these terms, the lender will deposit
                            the loan amount directly into your bank account. It
                            can be as soon as the next business day.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HowItWorksSection;
