import React from "react";

import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
    const footerlist = [
        { title: "How It Works", link: "/howitworks" },
        { title: "Rates & Fees", link: "/ratesandfees" },
        { title: "Lending Policy", link: "/lendingpolicy" },
        { title: "Terms Of Use", link: "/termsofuse" },
        { title: "Disclaimer", link: "/disclaimer" },
        { title: "Privacy Policy", link: "/privacypolicy" },
        { title: "CCPA", link: "/ccpa" },
        { title: "Unsubscribe", link: "/" },
    ];

    const { pathname } = useLocation();
    return (
        <section
            className={`bg-low-contrast-blue font-Montserrat mt-auto ${
                pathname !== "/login" ? "block" : "hidden"
            }`}
        >
            <div className="py-8  space-y-8 overflow-hidden md:mx-20 px-0 md:px-1.5 mx-6 mt-auto">
                <footer className="flex flex-wrap justify-center gap-4 lg:gap-6 border-b-black border-b pb-8 items-end">
                    <HashLink
                        to="/"
                        className="text-20px font-medium text-project-black hover:transition-all hover:ease-out hover:duration-300 transition-all ease-out duration-300 hover:text-project-orange"
                    >
                        <span>Home</span>
                    </HashLink>
                    {footerlist.map((el, index) => {
                        return (
                            <HashLink
                                key={index}
                                to={el.link}
                                className="text-16px font-normal text-project-black hover:transition-all hover:ease-out hover:duration-300 transition-all ease-out duration-300 hover:text-project-orange"
                            >
                                <span>{el.title}</span>
                            </HashLink>
                        );
                    })}
                </footer>
            </div>
        </section>
    );
};

export default Footer;
