import React from "react";
import Button from "../shared/Button";
import LoanForms from "../shared/LoanForms";
import Connected from "../../assets/images/connected-fast.png";
import CreditTypes from "../../assets/images/credit-types.png";
import SubmitOnline from "../../assets/images/submit-online.png";

const LoanSection = () => {
    return (
        <section className="z-[999999999999]">
            <div className="xl:container mx-auto font-Montserrat pt-12 md:mt-6 mt-14 xl:px-0 md:px-20 px-6 ">
                <div className=" mb-6 !z-[999999]">
                    <h1 className="font-semibold text-32px xl:relative  text-main-blue !z-[999999]">
                        How Much Do You Need?
                    </h1>
                    <div className="bg-project-orange mt-2 rounded h-1 w-20"></div>
                </div>
                <LoanForms className="mb-6 md:mb-10">
                    <Button
                        type="submit"
                        className="md:w-[268px] w-full  px-5 py-2.5 mb-2"
                    >
                        Submit
                    </Button>

                    <p className="capitalize font-light md:max-w-xs text-14px">
                        By submitting your information and completing this form,
                        you agree to our Privacy Policy and Disclaimer.
                    </p>
                </LoanForms>
                <div className="mt-24 pt-1 sm:mt-28 sm:pt-2 flex flex-col justify-center lg:flex-row lg:justify-between">
                    <div>
                        <img
                            className="mb-5 pb-0.5 mx-auto"
                            src={Connected}
                            alt="Connected"
                        />
                        <h1 className="text-center font-medium text-20px text-main-blue mb-4">
                            Get Connected Fast
                        </h1>
                        <p className="text-center font-normal text-16px text-dark-grey max-w-[210px] mx-auto mb-8">
                            You’ll Be Surprised How Fast It Is{" "}
                        </p>
                    </div>
                    <div>
                        <img
                            className="mb-5 pb-0.5 mx-auto"
                            src={CreditTypes}
                            alt="CreditTypes"
                        />
                        <h1 className="text-center font-medium text-20px text-main-blue mb-4">
                            Most Credit Types Welcome{" "}
                        </h1>
                        <p className="text-center font-normal text-16px text-dark-grey max-w-[210px] mx-auto mb-8">
                            A Wide Range Of Credits Are Supported
                        </p>
                    </div>
                    <div>
                        <img
                            className="mb-5 pb-0.5 mx-auto"
                            src={SubmitOnline}
                            alt="SubmitOnline"
                        />
                        <h1 className="text-center font-medium text-20px text-main-blue mb-4">
                            Submit Online
                        </h1>
                        <p className="text-center font-normal text-16px text-dark-grey max-w-[300px] mx-auto mb-8">
                            Hassle Free, Use Your Computer To Submit a Request
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoanSection;
