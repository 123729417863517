import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import Button from "../shared/Button";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as SiteLogo } from "./../../assets/images/siteLogoTSmall.svg";

import { HashLink } from "react-router-hash-link";

const navItems = [
    { title: "Home", link: "/" },
    { title: "How It Works", link: "/howitworks" },
    { title: "Lending Policy", link: "/lendingpolicy" },
    { title: "Rates And Fees", link: "/ratesandfees" },
    { title: "Returning Custome", link: "/login" },
];

function Header() {
    let activeStyle = { color: "#3A68D7" };
    const { pathname, hash } = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav
            className={` font-Montserrat  ${
                pathname === "/" && !hash ? `bg-main-blue` : `bg-white`
            }  `}
        >
            <div className="xl:container mx-auto md:pl-20 md:pr-20 xl:pr-0 xl:pl-0   md:py-8 py-10 pl-6 pr-[24px] ">
                <div className="flex items-center justify-between ">
                    <div className="">
                        <a
                            className={`font-semibold text-32px  ${
                                pathname === "/" && !hash
                                    ? `text-white`
                                    : `text-project-black`
                            }`}
                            href="/"
                        >
                            <SiteLogo />
                            {/* Logo */}
                        </a>
                    </div>
                    <div className="hidden lg:block">
                        <div className="flex items-center">
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    `mr-8 hover:transition-all hover:ease-out hover:duration-300 transition-all ease-out duration-300 hover:text-project-orange ${
                                        isActive ? "active" : ""
                                    } ${
                                        pathname === "/"
                                            ? `white`
                                            : `text-dark-grey`
                                    }`
                                }
                            >
                                <span>Home</span>
                            </NavLink>
                            <NavLink
                                to="/howitworks"
                                className={({ isActive }) =>
                                    `mr-8 hover:transition-all hover:ease-out hover:duration-300 transition-all ease-out duration-300 hover:text-project-orange ${
                                        isActive ? "active " : ""
                                    } ${
                                        pathname === "/"
                                            ? `text-light-grey`
                                            : `text-dark-grey`
                                    }`
                                }
                            >
                                <span>How It Works</span>
                            </NavLink>
                            <NavLink
                                to="/lendingpolicy"
                                className={({ isActive }) =>
                                    `mr-8 hover:transition-all hover:ease-out hover:duration-300 transition-all ease-out duration-300 hover:text-project-orange ${
                                        isActive ? "active " : ""
                                    } ${
                                        pathname === "/"
                                            ? `text-light-grey`
                                            : `text-dark-grey`
                                    }`
                                }
                            >
                                <span>Lending Policy</span>
                            </NavLink>
                            <NavLink
                                to="/ratesandfees"
                                className={({ isActive }) =>
                                    `mr-8 hover:transition-all hover:ease-out hover:duration-300 transition-all ease-out duration-300 hover:text-project-orange ${
                                        isActive ? "active " : ""
                                    } ${
                                        pathname === "/"
                                            ? `text-light-grey`
                                            : `text-dark-grey`
                                    }`
                                }
                            >
                                <span>Rates And Fees</span>
                            </NavLink>
                        </div>
                    </div>
                    <Button
                        className={`py-2.5 px-7 xl:ml-0 ml-auto xl:mr-0 lg:ml-0 lg:mr-0 mr-[35px] hidden xs:block ${
                            pathname === "/" ? "hover:text-white" : ""
                        }
              `}
                    >
                        Get Started
                    </Button>
                    <div className=" flex lg:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className={`inline-flex items-center justify-center  ${
                                pathname === "/" && !hash
                                    ? `text-white`
                                    : `text-project-black`
                            }`}
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isOpen ? (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>

            <Transition
                show={isOpen}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
            >
                {(ref) => (
                    <div className="lg:hidden" id="mobile-menu">
                        <div ref={ref} className="px-2 pt-2 pb-3 sm:px-10">
                            {navItems.map(({ link, title }) => {
                                return (
                                    <HashLink to={link}>
                                        <span
                                            className={`block px-10 py-2 text-base font-medium hover:transition-all hover:ease-out hover:duration-300 transition-all ease-out duration-300 hover:text-project-orange ${
                                                pathname === "/" && !hash
                                                    ? `text-white`
                                                    : `text-project-black`
                                            }`}
                                        >
                                            {title}
                                        </span>
                                    </HashLink>
                                );
                            })}
                        </div>
                    </div>
                )}
            </Transition>
        </nav>
    );
}

export default Header;
