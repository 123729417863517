import React from 'react'
import HowItWorksImg from "../assets/images/howitworks.png";
import Ellipse from "../assets/images/ellipse.svg";

const HowItWorks = () => {
  return (
    <section className="pt-5 md:pt-7 lg:pt-88px pb-76px md:pb-20 xl:container mx-auto md:px-20 px-6 xl:px-0 font-Montserrat">
      <div className="flex flex-col justify-between lg:flex-row-reverse gap-0 lg:gap-[131px] items-end">
        <div className="xs:ml-auto xs:mr-auto lg:mr-auto lg:ml-0 ml-1.5 mr-1.5 mb-20 pb-0.5 lg:pb-0.5 md:mb-16 md:pb-px flex justify-center flex-shrink-0 ">
          <img className="" src={HowItWorksImg} alt="howitworks" />
        </div>
        <div className="max-w-full lg:max-w-[533px] pb-0 lg:pb-8 mb-0 lg:mb-1">
          <h1 className='font-semibold text-32px  text-main-blue capitalize'>
            How It Works
          </h1>
          <div className='bg-project-orange mt-2 rounded h-1 w-[47px] mb-6'>
          </div>
          <p className="font-medium text-20px-30fh mb-6 text-dark-grey capitalize">
            All you need to do is to fill out one simple form via lowficolending.com. Instantly, we will forward your loan request to a lender or lending partner from our network of trustworthy brands. You can request a loan as low as $100 and up to $5,000. Please note that the exact loan size can vary depending on your location and our lenders’ programs. In most cases, you can expect a same-day response from our lenders or lending partners.</p>
        </div>
      </div>

      <div className="flex flex-col justify-center lg:flex-row gap-0 lg:gap-28">
        <div className="w-full">
          <p className="font-medium text-20px-30fh mb-6 text-dark-grey capitalize">
            Usually, it takes them no more than a few minutes to get back at you. In case you get approved for a loan, you may be taken to the lender’s official website where they will provide you with all necessary loan terms, fees and interest rates. In a scenario where you find the loan terms acceptable and agree to the loan offer, you may expect to get the loan funds into your bank account within one business day. Please note that most of the lenders and lending partners from our network will probably ask you to provide and confirm the following details before approving you for a loan:</p>
        </div>
        <div className="max-w-full lg:max-w-[456px]">
          <p className="font-medium text-20px-30fh mb-8 text-dark-grey">
            These are the criteria you must meet in order to get approved for a loan from our network’s lenders:</p>
          <div className="max-w-[456px]">
            <div className="flex items-start gap-2 mb-2">
              <img className="mt-1" src={Ellipse} alt="Ellipse" />
              <p className="text-16px text-dark-grey font-normal capitalize">Be permanently employed for at least 90 days.</p>
            </div>
            <div className="flex items-start gap-2 mb-2">
              <img className="mt-1" src={Ellipse} alt="Ellipse" />
              <p className="text-16px text-dark-grey font-normal capitalize">Either be a US citizen or a permanent resident in the U.S., and your age is 18+</p>
            </div>
            <div className="flex items-start gap-2 mb-2">
              <img className="mt-1" src={Ellipse} alt="Ellipse" />
              <p className="text-16px text-dark-grey font-normal capitalize">Have a monthly income of approximately $1,000 after tax.</p>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <img src={Ellipse} alt="Ellipse" />
              <p className="text-16px text-dark-grey font-normal capitalize">Hold a personal bank account.</p>
            </div>
            <div className="flex items-start gap-2 mb-2">
              <img className="mt-1" src={Ellipse} alt="Ellipse" />
              <p className="text-16px text-dark-grey font-normal capitalize">Be able to provide a valid home and/or business telephone number.</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={Ellipse} alt="Ellipse" />
              <p className="text-16px text-dark-grey font-normal capitalize">Have a valid email address.</p>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks