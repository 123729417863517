import React from 'react'
import Error from "../assets/images/Errorimg.png";

const Error404 = () => {
  return (
    <section className="xl:container mx-auto px-30px pt-24 mt-1 font-Montserrat pb-52 mb-3 md:pt-40 md:mt-2.5 md:pb-96 md:mb-0 lg:pt-16 lg:mt-1.5 lg:pb-28 lg:mb-px">
      <div>
        <h1 className="bg-gradient-to-b from-project-orange to-project-light-orange text-transparent bg-clip-text font-semibold text-48px text-center mb-4">Oops!</h1>
        <p className="capitalize text-28px text-center text-project-black font-medium mb-8 md:mb-3 max-w-md mx-auto">we can’t seem to find the page you are looking for </p>
        <img className="mx-auto max-w-full md:max-w-[612px] lg:max-w-[780px]" src={Error} alt="" />
      </div>
    </section>
  )
}

export default Error404