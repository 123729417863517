const Disclaimer = () => {
    return (
        <section className="xl:container mx-auto pt-10 md:pt-12 lg:pt-88px pb-20 md:px-20 xl:px-0 px-6 font-Montserrat">
            <div>
                <h1 className="font-semibold text-32px  text-main-blue">
                    Disclaimer
                </h1>
                <div className="bg-project-orange mt-2 rounded h-1 w-[95px] mb-6"></div>
                <div className="text-20px-30fh text-dark-grey">
                    <p className="mb-9">
                        Please note that lowficolending.com is neither a lender
                        nor makes any decisions regarding credits or loans.
                        lowficolending.com simply connects consumers with
                        appropriate lenders and lowficolending.com does not act
                        as an agent, correspondent or representative for any of
                        the lenders or service providers. lowficolending.com
                        neither prefers nor endorses any specific lender, loan
                        product or service provider. Users of
                        lowficolending.com services have no obligation to use
                        them in order to be connected with, submit a lender
                        connection request form or accept any loan products from
                        any lender or service provider. Please note that the
                        service of lowficolending.com is not accessible in all
                        states.
                    </p>
                    <p className="mb-9">
                        Submitting a request for a loan via lowficolending.com
                        comes with no fees for customers. lowficolending.com
                        neither is a lender nor provides any lending services.
                        lowficolending.com simply offers connecting customers
                        with an appropriate lender from our network using the
                        information they provide.
                    </p>
                    <p className="mb-9">
                        In case a lender accepts your request for a loan, you
                        will automatically be sent to an e-sign page on their
                        website. That is the moment when your lender is supposed
                        to disclose to you all information regarding any fees
                        and rates charged by them. Please make sure to
                        thoroughly and carefully read all the information
                        regarding fees and rates provided by your lender before
                        you agree to the loan terms or sign any of the
                        documents. Remember that you are not required to accept
                        the loan terms.
                    </p>
                    <p className="mb-9">
                        You should e-sign the loan terms and therefore agree to
                        the terms only if you determine that the lender’s terms
                        for the loan are acceptable to you. Once you sign the
                        document, you become part of a legally binding agreement
                        with the loan lender and therefore you hold the sole
                        responsibility for any risks and costs related to the
                        agreement. Please note that you have no binding
                        agreement obligations to lowficolending.com but to your
                        lender. Furthermore, note that all the fees and interest
                        rates related to the loan you accepted are decided by
                        your lender and not by lowficolending.com.
                    </p>
                    <p className="mb-9">
                        Payday loans and any other cash advances are not
                        long-term money solutions and serve only as short-term
                        relief. Any individuals with accumulated debt and/or
                        credit difficulties are advised to seek professional
                        advisory help regarding credit and debt. We strongly
                        encourage our consumers to read the page for Rates and
                        Fees in order to get informed about any possible cash
                        advances risks. Furthermore, we advise our customers to
                        get familiar with any applicable regulations and local
                        laws regarding cash advances as well as to consider any
                        possible short-term loan alternatives.
                    </p>
                    <p className="mb-9">
                        Please note that lowficolending.com services are not
                        accessible in all states. More specifically,
                        lowficolending.com does not operate on the territory of
                        Georgia, Arizona, Massachusetts, Arkansas, New York,
                        Virginia, West Virginia, Pennsylvania, Ohio and other
                        states where the law prohibits it. Please note that the
                        list of restricted states may be updated at any moment
                        and without sending a prior notice to customers. Any
                        interactions between lowficolending.com and you are
                        considered to take place in California state, no matter
                        where you access this site from.
                    </p>
                    <p className="mb-9">
                        Please note that lowficolending.com does not and cannot
                        guarantee to consumers that authorized lenders or any
                        other loan service providers will accept their loan
                        request. In case you get a loan offer,
                        lowficolending.com neither guarantees that any fees and
                        rates related to the loan will be affordable to you nor
                        that the loan is funded. Remember that your lender, not
                        lowficolending.com makes all decisions regarding your
                        loan.
                    </p>
                    <p className="mb-9">
                        Lenders and service providers, in most scenarios, will
                        not demand any credit checks from the leading three
                        credit bureaus (Equifax, TransUnion, and Experian). Most
                        of our authorized lenders and service providers perform
                        credit score checks via other agencies including but not
                        limited to DP Bureau and Teletrack. Usually, such
                        non-traditional credit score checks do not damage your
                        credit history. When you submit a request for a loan via
                        lowficolending.com, you agree and understand that such
                        alternative credit score checks may be performed by
                        lowficolending.com, our lenders and/or service
                        providers.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Disclaimer;
